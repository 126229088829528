import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  mounted() {
    this.updateDownLinkSpeed();
    if ("connection" in navigator) {
      navigator.connection.addEventListener("change", this.updateDownLinkSpeed);
    }
  },
  computed: {
    ...mapGetters("cvsstepup", [
      "sqaAlerts",
      "otpAlerts",
      "fuAlerts",
      "fpAlerts",
      "pollingLoader",
    ]),
    ...mapGetters("forgotflow", ["alerts"]),
    ...mapGetters("forgotflow", { forgotLoader: "submitLoader" }),
    ...mapGetters("login", [
      "getUnifiedLoginAlerts",
      "getEmptyUnifiedLoginAlerts",
      "getUnlockMyAccountAlerts",
      "getCurrentComponent",
      "getNavHistory",
      "submitLoader",
      "getCommonErrors",
      "getWhichRealm",
      "getHideBanner",
      "getDeliveryComponentName",
    ]),
    ...mapGetters("cvsstepup", { getTransactionLoader: "submitLoader" }),
    showAdobeBaner() {
      return !(this.flowType?.includes("forgot") || this.getHideBanner);
    },
    showBackButton() {
      return !this.getHideBanner;
    },
    // getClass() {
    //   return (
    //     this.getDeliveryComponentName == "NewOTPSummary" ||
    //     this.getDeliveryComponentName == "NewAddOTPComponent"
    //   );
    // },
  },
  watch: {
    // checking routechanges
    $route: {
      // While route changes we are setting navhistory
      handler(route) {
        console.log("route-change", route.fullPath);
        if (route) this.setNavigationObject(route.fullPath);
      },
      immediate: true,
      deep: true,
    },
    sqaAlerts(newVal) {
      if (newVal.length) this.showCommonErrors(this.$route.path);
    },
    otpAlerts(newVal) {
      if (newVal.length) this.showCommonErrors(this.$route.path);
    },
    fuAlerts(newVal) {
      if (newVal.length) this.showCommonErrors(this.$route.path);
    },
    fpAlerts(newVal) {
      if (newVal.length) this.showCommonErrors(this.$route.path);
    },
    alerts(newVal) {
      if (newVal.length) this.showCommonErrors(this.$route.path);
    },
    getUnifiedLoginAlerts(newVal) {
      console.log("getUnifiedLoginAlerts", newVal);
      if (newVal.length) this.showCommonErrors(this.$route.path);
    },
    getEmptyUnifiedLoginAlerts(newVal) {
      if (newVal.length) this.showCommonErrors(this.$route.path);
    },
    getUnlockMyAccountAlerts(newVal) {
      if (newVal.length) this.showCommonErrors(this.$route.path);
    },
  },
  methods: {
    ...mapMutations("login", [
      "setCommonErrors",
      "setNavHistory",
      "setUnifiedLoginAlerts",
      "setEmptyUnifiedLoginAlerts",
    ]),
    ...mapMutations("cvsstepup", [
      "setSQAAlert",
      "setOTPAlert",
      "setFuAlert",
      "setFpAlert",
      "setLoginChatbot",
    ]),
    ...mapMutations("forgotflow", ["setalert"]),
    /**
     * Setting navigation history for back button and rendering
      the required component for all flows
     */
    setNavigationObject(path) {
      this.setGlobalNav("mybusinesslimited");
      let payload = {};
      // Here onwards login flows components
      if (
        path.includes("login/unifiedlogin") ||
        path.includes("login/mobile")
      ) {
        if (path.includes("login/unifiedlogin")) this.setGlobalNav("unified");
        this.setNavHistory();
        if (!this.$route.query?.loginAssistantUid && login_assistant_unlockaccount_metrics) this.setLoginChatbot(false);
      } else if (path.includes("login/password")) {
        payload.path = path;
        payload.name = "NewPwdLessPassword";
      } else if (path.includes("login/options")) {
        payload.path = path;
        payload.name = "NewOption";
      } else if (path.includes("login/accountlocked")) {
        payload.path = path;
        payload.name = "ResetPassword";
      } else if (path.includes("login/deliveryMethodLocked")) {
        payload.path = path;
        payload.name = "NewDeliveryMethodLocked";
      } else if (path.includes("login/verify/delivery")) {
        payload.path = path;
        payload.name = "NewRootAddDelivery";
      }
      // Here onward stepup flows components
      else if (path.includes("/otpdelivery")) {
        payload.path = path;
        payload.name = "NewOtpDelivery";
      } else if (path.includes("/otpvalidate")) {
        payload.path = path;
        payload.name = "NewOtpValidate";
      } else if (path.includes("login/stepup/sqa")) {
        payload.path = path;
        payload.name = "NewSqaComponent";
      }
      // Here onwards forgot flows components
      else if (path.includes("recovery/forgotusername")) {
        payload.path = path;
        payload.name = "NewAMForgotUsrnmComponent";
        if (!this.$route.query?.loginAssistantForgotUserId && login_assistant_unlockaccount_metrics)
          this.setLoginChatbot(false);
      } else if (path.includes("recovery/forgotpassword")) {
        payload.path = path;
        payload.name = "NewAMForgotPwdComponent";
        if (!this.$route.query?.loginAssistantForgotPassword && login_assistant_unlockaccount_metrics)
          this.setLoginChatbot(false);
      } else if (path.includes("recovery/resetpassword")) {
        payload.path = path;
        payload.name = "NewAMResetPasswordComponent";
      }
      // Display respective component
      if (payload?.path !== this.getCurrentComponent?.path && payload?.path) {
        this.setNavHistory(payload);
      }
      // While route changes we are showing error message
      this.showCommonErrors(path);
    },
    // Set common Errors based on components
    showCommonErrors(path) {
      console.log("common-error", path);
      let error = [];
      this.setCommonErrors();
      // Setting errors for unified - password - mobilelogin - accountlocked - options pages
      if (
        path.includes("login/unifiedlogin") ||
        path.includes("login/mobile") ||
        path.includes("/password") ||
        path.includes("login/accountlocked") ||
        path.includes("/options")
      ) {
        error = this.getUnifiedLoginAlerts.length
          ? this.getUnifiedLoginAlerts
          : this.getEmptyUnifiedLoginAlerts.length
          ? this.getEmptyUnifiedLoginAlerts
          : [];
      }
      // Setting errors for otpdelivery - otpvalidate - sqa -pages
      else if (
        path.includes("/otpdelivery") ||
        path.includes("/otpvalidate") ||
        path.includes("login/stepup/sqa")
      ) {
        error = this.sqaAlerts.length
          ? this.sqaAlerts
          : this.otpAlerts.length
          ? this.otpAlerts
          : this.fuAlerts.length
          ? this.fuAlerts
          : this.fpAlerts.length
          ? this.fpAlerts
          : [];
      }
      // Setting errors for forgotusername - forgotpassword - resetpassword pages
      else if (
        path.includes("recovery/forgotusername") ||
        path.includes("recovery/forgotpassword") ||
        path.includes("recovery/resetpassword")
      ) {
        error = this.alerts.length
          ? this.alerts
          : this.fuAlerts.length
          ? this.fuAlerts
          : this.fpAlerts.length
          ? this.fpAlerts
          : [];
      } else if (path.includes("/verify/delivery")) {
        error = this.getUnlockMyAccountAlerts.length
          ? this.getUnlockMyAccountAlerts
          : [];
      }
      this.setCommonErrors(error);
    },
    // Clear all the error messages
    clearAllErrors() {
      this.setUnifiedLoginAlerts([]);
      this.setEmptyUnifiedLoginAlerts([]);
      this.setSQAAlert([]);
      this.setOTPAlert([]);
      this.setFuAlert([]);
      this.setFpAlert([]);
      this.setalert([]);
    },
    // Redirect to login page
    redirectToLogin() {
      this.$refs[this.getCurrentComponent.name].cancelRecovery();
    },
    // Set globalnavbar based on params
    setGlobalNav(appid) {
      gnavdl = {
        bu: "smb",
        appid,
      };
      this.createGlobalNav();
    },
    // Createing global nav
    createGlobalNav() {
      var globalNav = document.createElement("script");
      globalNav.setAttribute(
        "src",
        "https://www.verizon.com/business/ngnav/smb.js"
      );
      globalNav.setAttribute("async", "async");
      document.head.appendChild(globalNav);
    },
    updateDownLinkSpeed() {
      if ("connection" in navigator) {
        console.log("navigator.connection");
        this.downlinkSpeed = navigator.connection.downlink;
      }
    },
  },
  beforeDestroy() {
    if ("connection" in navigator) {
      navigator.connection.removeEventListener(
        "change",
        this.updateDownLinkSpeed
      );
    }
  },
};
