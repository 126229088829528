<template>
<div>
  <BlockUI v-if="submitLoader" :url="url"></BlockUI>
  <!-- <div v-else-if="!submitLoader" class="setup-secure-profile" style="margin-top: 20px;">
    <div class="row mx-0">
      <div class="col-12 mx-0 px-0">
        <h1  class="marbottom10">Update secret question</h1>
        <MessagesComponent></MessagesComponent>
        <hr class="marbottom10 mt-0" />
        <p class="req-msg">All fields are required</p>
        <ul class="para2 marbottom30 martop40" style="margin-left: 20px">
          <li>Answers are not case sensitive</li>
           <li>Must be from 3-40 characters</li>
            <li>Only letters (A-Z), numbers (0-9), spaces and periods are supported</li>
             <li>Choose an answer that is memorable and not easy to guess</li>
        </ul>
      </div>
    </div> -->
  <!-- <WelcomeHubBanner v-if="showWelcomeHubBanner"/> -->
    <div class="vbg-rsp-title-container">
      <h1 class="vbg-rsp-title-h1">
        {{ $t("message.postauth_sqa_tittle") }}
      </h1>
    </div>
    <div class="vbg-rsp-des">
      <p>{{ $t("message.postauth_sqa_description") }}</p>
    </div>
    <div class="vbg-rsp-form-container">
    <ValidationObserver ref="form" v-slot="{ handleSubmit}">
      <form @submit.prevent="handleSubmit(onSubmit())">
        <SQAComponent
          :isProfileComplete="isProfileComplete"
          @child-challengeQuestion="challengeQuestionValue"
          @child-challengeAnswer="challengeAnswerValue"
        ></SQAComponent>
        <div class="btn-container">
          <div class="vbg-mb-btn-container">
            <button
              :disabled="disableButton"
              :class="disableButton ? 'vbg-ff-disabled' : ''"
              :tabindex="secretQuestions.length+2"
              class="btn vbg-ff-btn-continue vbg-ff-btn-width140 spacing05"
              type="submit"
              id="btn_update"
              name="btn_update"
            >
              <span id="sp_sendcode">Update</span>
            </button>

            <button
              class="btn vbg-ff-btn-cancel vbg-ff-btn-width140 spacing05"
              :tabindex="secretQuestions.length+3"
              type="reset"
              @click="cancel()"
              id="btn_back"
              name="btn_back"
            >
              <span id="sp_sendcode">Cancel</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SQAComponent from "@/profile/components/core/SQAComponent";
import MessagesComponent from "@/profile/components/core/MessagesComponent";
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import $ from "jquery";
import commonMixin from '@/common/mixin/commonMixins'
import translations from "@/common/assets/json/messages1.json";
// import WelcomeHubBanner from "../../../vbgauth/components/WelcomeHubBanner.vue";

export default {
  name: "ModifySQAComponent",
  data() {
    return {
      status: false,
      challengeQuestion: null,
      challengeAnswer: "",
      whichRealm: true,
      invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      isProfileComplete: true,
      // showWelcomeHubBanner:false
    };
  },
  mixins: [commonMixin],
  async created() {
    setPageDetails('Profile:Change SQA'); 
  },
  components: {
    SQAComponent,
    MessagesComponent,
    // WelcomeHubBanner
  },
  methods: {
    ...mapMutations('profile', ['setQuickSettingsUrl']),
    ...mapActions("profile", [
      "OttSetUP",
      "getUserProfile",
      "isTxnValid",
      "updateTargetUrl",
      "updateSubmitLoder"
    ]),
    challengeQuestionValue: function (params) {
      console.log("here at method propertties")
      this.challengeQuestion = params;
    },
    challengeAnswerValue: function (params) {
      this.challengeAnswer = params;
    },

    onSubmit() {
      console.log("user clicks on the submit button")
      if (this.challengeAnswer.length >= 3 && (this.invalidCharRegex.test(this.challengeAnswer))) {
        // update button is disabled so on click no action to be taken
      console.log("user clicks on the submit button at 1")

      } else {
      console.log("user clicks on the submit button at 2",this.challengeQuestion )

        let payload = {
          uuID: this.uuID,
          sqaTO: [
            {
              id: this.challengeQuestion,
              pwd: this.challengeAnswer
            },
          ],
          tcAccepted: false,
        };
        this.modifySQA(payload)
        this.updateSubmitLoder(true)
      }
     },

    ...mapActions("profile", ["modifySQA", "resetMessages","updateSubmitLoder","updateProgressDataAction"]),

    cancel() {
      this.resetMessages();
      if(this.userType == 'VZW') {
        window.location.href = this.quickSettingsUrl;
      } else {
        this.$router.push({ path: 'overview', query : { target: this.logOutGotoUrl}});
      }
    },
    parseUrl() {
      const queryParams = new URLSearchParams(window.location.search);
      const quickSettings = queryParams.get('quickSettings');
      const fragment = window.location.hash.substring(1);
      const [fragment1, ...rest] = fragment.split('&');
      const quickSettingsParam = quickSettings+'#'+fragment1;
      this.setQuickSettingsUrl(quickSettingsParam)
    },
  },

  computed: {
    ...mapGetters("profile", [
      "errorMsg",
      "showerrorMsg",
      "infoMsg",
      "showinfoMsg",
      "uuID",
      "gotoProfileSettings",
      "firstName",
      "lastName",
      "stepupVerified",
      "logOutGotoUrl",
      "sqa",
      "secretQuestions",
      "submitLoader",
      "quickSettingsUrl",
      "userType"
    ]),
    disableButton() {
      // console.log("the user entered answer", this.challengeAnswer)
      return (this.challengeAnswer.length >= 3 && !(this.invalidCharRegex.test(this.challengeAnswer)) ) ? false : true
    },
    // challengeQuestionValue: function (params) {
    //   console.log("here at computed propertties", params)
    //   this.challengeQuestion = params;
    // },
  },
  mounted() {
//     const payload =  {
//   "ecpdId": "8341818", // mandatory
//   "userId": "8341818_SMB_QA2_SPOC", // mandatory
//   "clientId": "MVB", // mandatory
//   "ecpdProfileCreationDate": "12/06/2024", // mandatory
//   "role": "PrimaryContact" // mandatory
// }
  // this.updateProgressDataAction(payload)
  //     if(this.userType === 'VZW'){
  //   this.showWelcomeHubBanner = true
  // }
// URLSearchParams not supported in IE
  // let params = new URLSearchParams(window.location.search)
      if(this.getParameterByName('stepupVerification')) {
        this.$store.commit('profile/setStepupVerified', true)
      }else{
        this.$store.commit('profile/setStepupVerified', false)
      }
    this.$store.commit('profile/setAttributedUpdated', "modifysqa")
    this.getUserProfile(this.getParameterByName("target"));
    // this.$store.commit('profile/setLoader', true)
    this.updateSubmitLoder(true)
    if (iamvar_oneSessionPerLoginEnable !== true) {
      this.isTxnValid(this.getParameterByName("target"));     
    }
    this.updateTargetUrl(this.getParameterByName("target"))       
    this.resetMessages();

    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      var iamvar_appCookie = getCookie('X-VZ-C_APP')
      if(iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }
    this.changeContextBiocatch('postauth_modify_question_page')
    sessionStorage.removeItem('optSelected');
    this.parseUrl();
  },
  watch: {
    sqa: function(newVal) {
      if(newVal) {
        this.challengeQuestion = newVal.id
        this.updateSubmitLoder(false)
      }
    },
    gotoProfileSettings: function (newVal) {
      if (newVal) {
        if(this.userType == 'VZW') {
          window.location.href = this.quickSettingsUrl;
        } else {
          this.$router.push({ path: 'overview', query : { target: this.logOutGotoUrl}});
        }
      }
    },
  },
};
</script>

<style scoped>
.btn-dark {
  color: #fff;
  background-color: black;
  border-color: #46b8da;
}
.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
li{
  list-style-type: disc;
  /*list-style-position: inside;*/
}
.btn-container {
  margin-top: 64px;
  padding: 0px;
}
.vbg-rsp-des {
  color: #000000 !important;
  font-size: 14px; 
  letter-spacing: 0; 
  line-height: 16px;
}
.vbg-ff-btn-width140 {
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  height: 44px !important;
  text-align: center;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  padding: 12px;
  font-size: 16px;
}
@media screen and (max-width: 1024px){
  .setup-secure-profile {
    margin-left: 0 !important;
  }
  .btn-container {
    margin-top: 48px;
  }
}
@media  (min-width: 350px) and ( max-width: 550px){
  .setup-secure-profile{
    /*width: 95% !important;*/
  }
}
@media  (min-width: 768px) and ( max-width: 1024px){
  .setup-secure-profile {
    width: 100% !important; 
  }
}
@media (max-width: 768px) {
  .oauth-button {
    width: 100%;
    margin-bottom: 15px;
    margin-left: 0 !important;
  }
  .vbg-mb-btn-container {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .oauth-button {
    width: 47% !important;
    margin-right: 0 !important;
    margin-bottom: 50px !important;
  }
  .tablet-secondary-buttom {
    float: right;
  }
}

</style>
