<template>
  <div>
    <div class="vbg-rsp-main-container">
      <BlockUI v-if="submitLoader" :url="url"></BlockUI>
      <div class="vbg-rsp-title-container">
        <h1 class="vbg-rsp-title-h1">{{ $t("message.reset_security_profile_otp_summary_title") }}</h1>
      </div>
      <div class="otp-summary-description-text">
        <p>
          {{ $t("message.reset_security_profile_otp_summary_description") }}
        </p>
      </div>
      <div class="ovrview-msg" v-if="isButtonDisabled">
        <div class="vbg-ff-messagesInfo" v-bind:class="'information'">
          <div class="vbg-ff-msg-container">
            <div class="messageWrapper">
              <div class="messageIcon msg-icon-position">
                <div class="messageImage" :class="'informationMessageImage'"></div>
              </div>
              <p class="messageContentUn" v-html="max_devices_limit_msg">
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="vbg-rsp-devices-table">
        <table class="view-otp-table">
          <thead class="table-head">
            <tr>
              <td>Delivery method</td>
              <td v-if="otpDeviceList.otpDeviceList.length != 1" >Remove</td>
            </tr>
          </thead>

          <tbody class="table-body table-text">
            <tr :class="[otpDeviceList.otpDeviceList.length - 1 == name ? 'table-body' : '']"
              v-for="(value, name) in otpDeviceList.otpDeviceList" :key="name">
              <td v-if="value.otp_type.toLowerCase() == 'email'">Email <span class="otp_device_email">{{ value.otp_value
              }}</span></td>
              <td v-if="value.otp_type.toLowerCase() == 'sms'">Text <span class="otp_device_text">{{ value.otp_value
              }}</span></td>
              <td v-if="value.otp_type.toLowerCase() == 'ivr'">Call <span class="otp_device_call">{{ value.otp_value
              }}</span></td>
              <td>
                <a @click="deleteDevice(value)" class="vbg-rsp-device-table-delete-icon"
                  v-if="otpDeviceList.otpDeviceList.length != 1" :id="'delete-device-'+name" name="delete-device"><img class="delete-icon-size"
                    src="@/common/assets/styles/corp/images/delete_bin.png" /></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="btn-reverse-mobile">
      <button v-if="!isSDARFlow" type="submit" class="btn vbg-ff-btn-continue continue_btn vbg-ff-btn-width140 spacing05" @click="continueLogin()" id="rsp-otp-device-continue-btn" name="rsp-otp-device-continue-btn">
        <span>Continue</span>
      </button>
      <button v-if="isSDARFlow && !sdarUserType" 
        type="submit" 
        class="btn vbg-ff-btn-continue continue_btn vbg-ff-btn-width140 spacing05 sdar" @click="continueLogin()" 
        id="rsp-otp-device-continue-btn" 
        name="rsp-otp-device-continue-btn"
      >
        Continue
      </button>
      <button v-if="isSDARFlow && sdarUserType
        && otpDeviceList.otpDeviceList.length > 1" 
        type="submit" 
        class="btn vbg-ff-btn-continue continue_btn vbg-ff-btn-width140 spacing05 sdar" @click="continueLogin()" 
        id="rsp-otp-device-continue-btn" 
        name="rsp-otp-device-continue-btn"
      >
        Continue
      </button>
      <button 
        @click="showAddDelivery()" 
        @click.native="resetMessages"
        type="button"
        :disabled="isButtonDisabled"
        :class="{ 'disabled' : isButtonDisabled }" 
        class="btn vbg-ff-btn-cancel add-delivery-method-btn vbg-ff-btn-width140 spacing05"
        id="rsp-add-delivery-btn"
        name="rsp-add-delivery-btn"
      >
        <span>Add delivery method</span>
      </button>
    </div>

    <div id="modal-popup" class="modal fade">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <h3 class="mt-0">Are you sure you want to delete?</h3>
          <a data-dismiss="modal" class="pull-right close-icon-popup vbg-rsp-close-icon-popup"></a>
          <p class="spacing05">You are about to delete {{ deviceToBeDelete }} as a verification delivery method.</p>
          <div class="modal-popup-btn-box">
            <button type="submit" data-dismiss="modal" class="btn  vbg-ff-btn-continue modal-popup-btn vbg-ff-btn-width140 spacing05" id="rsp-delete-btn" name="rsp-delete-btn"
            @click="conformDeleteDevice()">
            <span>Delete</span>
          </button>
          <button class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05" data-dismiss="modal" id="rsp-cancel-delete-btn" name="rsp-cancel-delete-btn">
            <span>Cancel</span>
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import translations from "@/common/assets/json/messages1.json";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { setPageDetails, errorSet } from "../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";

export default {
  name: "ResetSecurityProfileOTPSummary",
  props: [],
  components: {},
  data() {
    return {
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      delSelectedDevice: "",
      deviceToBeDelete: "",
      max_devices_limit_msg: translations.en.message['reset_security_profile_otp_summary_max_devices'],
      isSDARFlow: iamvar_polarisFlowToggle,
    };
  },
  beforeDestroy() {

  },
  mixins: [commonMixin],
  async created() {
    setPageDetails("Reset Security Profile: Review Delivery methods");
  },
  mounted() {
    this.changeContextBiocatch('rsp_review_delivery_methods_page')
  },
  methods: {
    ...mapActions('profile', [
      "getOtpDeviceList",
      "deleteOTPDevice",
      "callRedirectURL",
      "resetMessages",
      "updateGotoOtp",
      "updateGotoOtpOvw"
    ]
    ),
    ...mapActions("login", [ "updateUnlockMyAccountAlerts" ]),
    ...mapMutations("profile", ['setLoader']),
    getMsgIcon(index) {
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      if (index.type == 'error' || index.type == 'warning' || index.type == 'list') {
        return "newErrorMessageImage"
      } else {
        return index.type + "MessageImage";
      }
    },
    deleteDevice(item) {
      console.log("test value log", item);
      this.deviceToBeDelete = item.otp_value;
      this.delSelectedDevice = item.guid;
      $("#modal-popup").modal({
        backdrop: "static",
      });
    },
    continueLogin() {
      let payload = {
        uuID: this.uuID,
      };
      this.setLoader(true);
      this.callRedirectURL(payload);
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {

      }
      return;
    },
    cancel() {
      this.resetMessages();
      this.$router.push({ path: "overview" });
    },
    conformDeleteDevice() {
      let payload = {
        uuID: this.uuID,
        otpDeviceList: [
          {
            guid: this.delSelectedDevice,
            otp_type: this.deviceToBeDelete
          },
        ],
      };

      this.deleteOTPDevice(payload);
    },
    moveover() {
      $("#Pc1Info").popover({ trigger: "hover focus" });
    },
    showAddDelivery() {
      this.updateUnlockMyAccountAlerts([])
      this.updateGotoOtpOvw(false)
      this.updateGotoOtp(true)
    }
  },
  computed: {
    ...mapGetters("profile", [
      "otpDeviceList",
      "uuID",
      "errorMsg",
      "showerrorMsg",
      "infoMsg",
      "showinfoMsg",
      "redirectUrl",
      "submitLoader",
      "changeURL",
      "firstName",
      "lastName",
      "showProcessLoader",
      "sdarUserType"
    ]),
    isButtonDisabled() {
      if(this.otpDeviceList && this.otpDeviceList.otpDeviceList){
        return this.otpDeviceList.otpDeviceList.length == 5;
      } else {
        return false
      }
        
    },
  },
  watch: {
    showProcessLoader: function (newVal) {
      if (newVal === true) {
        this.$router.push({ path: "redirecting" });
      }
    },
  },
};
</script>
<style scoped>
.messageContentUn {
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 0px;
  margin: 0px;
  color: #ffffff
}

.vbg-rsp-title-h1 {
  color: #000;
}

.ovrview-msg {
  padding-bottom: 24px;
  max-width: 914px;
}

#modal-popup {
  width: 100% !important;
  position: absolute !important;
}

.modal-dialog {
  width: 560px;
  height: 308px;
  margin: 0 auto !important;
  position: relative;
  top: 33%;
}

.modal-content {
  width: 560px;
  height: 308px;
  padding: 48px !important;
}

#modal-popup h3 {
  margin-bottom: 24px;
  font-family: "VzNHGeDS-bold11", Arial, Helvetica, sans-serif !important;
}

#modal-popup p {
  margin-bottom: 72px;
}

.continue_btn {
  margin-top: 32px;
  margin-right: 12px;
}

.otp_device_email {
  margin-left: 15px;
}

.otp_device_text {
  margin-left: 24px;
}

.otp_device_call {
  margin-left: 25px;
}

.otp-summary-description-text {
  padding-bottom: 32px;
  color: #000;
}
.table-text {
  color: #000;
}
.vbg-rsp-device-table-delete-icon {
  margin-left: 19px;
}

.add-delivery-method-btn {
  margin-top: 32px;
}

.msg-icon-position {
  padding: 0px;
  margin-right: 12px;
}

@media (min-width: 280px) {
  .closeMessageImg {
    position: static !important;
  }
}
@media (max-width: 442px) {
  .vbg-rsp-close-icon-popup {
    margin-top: 48px !important;
  }
}
@media (max-width: 768px) {
  .modal-content {
    padding: 48px 16px !important;
  }
  .vbg-rsp-close-icon-popup {
    left: 0px !important;
    margin-top: 62px !important;
    margin-right: 0px !important;
  }
  .modal-popup-btn {
    height: 44px !important;
    line-height: 18px;
    font-size: 16px;
    padding: 12px;
    text-align: center;
  }
}
@media  (min-width: 443px) and ( max-width: 768px){
  .vbg-rsp-close-icon-popup {
    margin: 62px 14px !important;
  }
}
@media (max-width: 1024px) {
  .messageContent {
    flex: 1;
  }
}

@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
}

@media (max-width: 425px) {

  .messageContent,
  .messageContentUn {
    width: 232px;
  }
}

@media (max-width: 1024px) {
  .oauth-flow-w-100 {
    width: 100% !important;
  }

}

@media (max-width: 540px) {
  .modal-dialog {
    top: 0;
  }

  .modal-content {
    height: auto;
  }

}
@media (min-width: 1025px) {
  .continue_btn {
  width: 220px;
  height: 44px;
  }
  .add-delivery-method-btn {
  width: 220px;
  height: 44px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .btn.vbg-ff-btn-cancel {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  .add-delivery-method-btn {
    float: right;
    margin-top: 0px;
    margin-bottom: 64px
  }

  .continue_btn {
    margin-top: 16px;
    margin-bottom: 12px !important;
  }
}

@media (min-width: 280px) and (max-width: 768px) {
  .modal-dialog{
    width: 100%;
    height: 100%;
  }
  .modal-content{
    width: 100%;
    height: 100%;
  }
  .modal-dialog {
    top: 0;
  }
  .btn-reverse-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
  .add-delivery-method-btn {
    margin-top: 16px;
    margin-bottom: 12px !important;
  }

  .continue_btn {
    float: right;
    margin-top: 0px;
    margin-bottom: 64px
  }
}

@media (min-width: 280px) and (max-width: 1024px) {
  .oauth-flow-w-100 {
    /* width: 47% !important; */
    margin-right: 0 !important;

  }

}
@media (max-width: 482px) {
  .msg-icon-position {
    padding-bottom: 60px !important;
  }
}
</style>
  
