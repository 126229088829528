var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.showMtn)?_c('h1',{staticClass:"new_pwdless_main_heading pwdles_pass_main_heading"},[_vm._v(" Select a verification method ")]):_vm._e(),(!_vm.showMtn)?_c('div',{staticClass:"otp_delivery_sub_text new_pwdless_sub_heading"},[_vm._v(" Message and data rates may apply. ")]):_vm._e(),_c('li',_vm._l((_vm.devices),function(device,index){return _c('div',{key:device.deviceID,staticClass:"pwdless_options_section",attrs:{"id":device.deviceID,"tabindex":index + 3},on:{"click":function($event){return _vm.sendOTP(device, index)}}},[_c('div',{staticClass:"pwdless_secure_option pwdless_secure_option_gap"},[_c('div',{staticClass:"pwdless_option"},[_c('div',{staticClass:"pwdless_option_icon"},[_c('a',[_c('img',{attrs:{"src":_vm.getIconURL(device),"alt":device.deviceType}})])]),_c('div',{staticClass:"delivery_option_with_msg"},[_c('a',[_vm._v(" "+_vm._s(device.deviceType == "BASIC" || device.deviceType == "SMARTPHONE" ? "Text" : device.deviceType == "EMAIL" ? "Email" : device.deviceType == "IVR" ? "Call" : device.deviceType)+" "),_c('span',{staticClass:"delivery_method_recommented_delivery"},[(
                    device.vzCarrier &&
                    (device.deviceType == 'SMARTPHONE' ||
                      device.deviceType == 'BASIC')
                  )?_c('span',{staticClass:"secure_text"},[_vm._v("Recommended")]):_vm._e()]),_c('div',{staticClass:"pwdless_delivery_link",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(device.deviceValue)+" ")]),(
                  _vm.flowType == 'forgot_password' &&
                  !(
                    device.deviceType == 'SMARTPHONE' ||
                    device.deviceType == 'BASIC'
                  )
                )?_c('div',{staticClass:"pwdless_delivery_addional_text"},[_vm._v(" Additional verification needed ")]):_vm._e()])])])])])}),0),(this.pwdPassWord == '')?_c('div',{staticClass:"new_pwdless_remember_me",staticStyle:{"margin-top":"24px"}},[_c('p',{staticClass:"checkbox_line check_para check_bg"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.defaultMethod),expression:"defaultMethod"}],staticClass:"tc checkbox_input check_box_border",attrs:{"type":"checkbox","id":"ch"},domProps:{"checked":Array.isArray(_vm.defaultMethod)?_vm._i(_vm.defaultMethod,null)>-1:(_vm.defaultMethod)},on:{"change":function($event){var $$a=_vm.defaultMethod,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.defaultMethod=$$a.concat([$$v]))}else{$$i>-1&&(_vm.defaultMethod=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.defaultMethod=$$c}}}}),_c('label',{staticClass:"label_text",attrs:{"for":"ch"}},[_vm._v("Make this my default log in method.")])])]):_vm._e(),(
      this.rememberThisDeviceEnable == 'basic' &&
      _vm.flowType !== 'login_assistant' &&
      !_vm.flowType.includes('forgot') &&
      !_vm.otpAlwaysOn &&
      _vm.whichRealm
    )?_c('div',{staticClass:"new_pwdless_remember_me"},[_c('div',{staticClass:"new_pwdless_checkbox_content_otp"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rememberThisDevice),expression:"rememberThisDevice"}],staticClass:"new_pwdless_checkbox dark_bg_checkbox",attrs:{"name":"remember_me","type":"checkbox","id":"ch"},domProps:{"checked":Array.isArray(_vm.rememberThisDevice)?_vm._i(_vm.rememberThisDevice,null)>-1:(_vm.rememberThisDevice)},on:{"change":function($event){var $$a=_vm.rememberThisDevice,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.rememberThisDevice=$$a.concat([$$v]))}else{$$i>-1&&(_vm.rememberThisDevice=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.rememberThisDevice=$$c}}}}),_c('span',{staticClass:"checkbox_primary_text",attrs:{"for":"ch"}},[_vm._v(" Remember this device for quick access. ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }