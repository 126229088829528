<template>
  <div class="over-view-main-container">
    <div class="breadcrumb-container">
      <a class="btn-breadcrumb-home" @click="redirect()" id="home_breadcrumb" name="home_breadcrumb">Home</a>
      <span class="breadcrumb-icon-caret-right"> <img class="caret-position"
          src="@/common/assets/styles/corp/images/right-caret.png" /> </span>
      <span class="btn-breadcrumb-myprofile">My profile</span>
    </div>
    <!-- <div class="breadcrumb-mobile">
      <span class="breadcrumb-icon-caret-right"> <img class="caret-position"
                    src="@/common/assets/styles/corp/images/left-caret.png" /> </span>
      <span class="btn-breadcrumb-back" @click="redirect()">Back</span>
    </div> -->
    <div class="overview-grid-layout">
      <!-- <MessagesComponent></MessagesComponent> -->
      <div class="security-details">
        <h1 class="overView-Title">Security details</h1>
      </div>

      <!-- <div class="col-xs-6 padleft0 returnProfile">
          <button class="btn primary_black martop20 marbottom20" @click="redirect()"
                >Return to Profile</button>
        </div> -->


      <!-- <div class="col-12 h-20 d-inline-block" style="clear: both;"></div> -->
      <div class="user-details-container">
        <div class="user-details-first-container">
            <div><p class="overViewTitle-text">User name</p><p>{{userName}}</p></div>
            <div class="user-details-email"><p class="overViewTitle-text">Email address</p><p>{{emailAddress}}</p></div>
            <div><p class="overViewTitle-text">Last login / time</p><p>{{loginLastTime}}</p></div>
          </div>

      </div>
      <!--<table class="tb-profile">
          <thead class="table-head">
            <tr class="para2">
              <td class="padleft0">Username</td>
              <td class="padleft0">Email address</td>
              <td class="padleft0">Last login / time</td>
            </tr>
          </thead>
        <tbody class="table-body">
              <tr class="parawithoutbold">
                <td class="padleft0">{{userName}}</td>
                <td class="padleft0">{{emailAddress}}</td>
                <td class="padleft0">{{loginLastTime}}</td>
              </tr>
            </tbody>
        </table>-->

    </div>
    <hr class="hr-bar pwd-hr-position" />
    <h2 class="chg-pwd-pos">Password</h2>
    <button type="submit" id="change_password_button" name="change_password_button"
              @click="callStepUP('modifypassword')" class="btn vbg-ff-btn-cancel btn_chng_pwd vbg-ff-btn-width140 spacing05"
            >
      <span>Change password</span>
    </button>


    <hr class="hr-bar hr-pos-24" />
    <h2 class="hr-pos-24">Secret question</h2>
    <h3 class="current-secret-quest hr-pos-12">Current secret question</h3>
    <p v-if="sqa" class="parawithoutbold chg-pwd-pos">{{sqa.message}}</p>
    <button type="submit" id="change_question_button" name="change_question_button"
              @click="callStepUP('modifysqa')" class="btn vbg-ff-btn-cancel btn_chng_pwd vbg-ff-btn-width140 spacing05"
            >
      <span>Change question</span>
    </button>

    <hr class="hr-bar hr-pos-24" />
    <h2 class="change-pass-text chg-pwd-pos">Verification delivery methods</h2>
    <div class="ovrview-msg" v-if="isButtonDisabled">
      <div class="vbg-ff-messagesInfo" v-bind:class="'information'">
        <div class="vbg-ff-msg-container">
          <div class="messageWrapper">
            <div class="messageIcon msg-icon-position">
              <div class="messageImage" :class="'informationMessageImage'"></div>
            </div>
            <p class="messageContentUn" v-html="max_devices_limit_msg">
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="devices-table">
      <table class="view-otp-table">
        <thead class="table-head">
          <tr>
            <td>Delivery method</td>
            <td v-if="otpDeviceList.otpDeviceList.length != 1">Remove</td>
          </tr>
        </thead>

        <tbody class="table-body">
          <tr :class="[otpDeviceList.otpDeviceList.length - 1 == name ? 'table-body' : '']"
            v-for="(value, name) in otpDeviceList.otpDeviceList" :key="name">
              <td class="table_text" v-if="value.otp_type.toLowerCase() == 'email'">Email <span class="device-type-pos">{{ value.otp_value }}</span></td>
              <td v-if="value.otp_type.toLowerCase() == 'sms'">Text <span class="device-type-pos">{{ value.otp_value }}</span></td>
              <td v-if="value.otp_type.toLowerCase() == 'ivr'">Call <span class="device-type-pos">{{ value.otp_value }}</span></td>
            <td>
              <a @click="deleteDevice(value)" class="vbg-rsp-device-table-delete-icon"
                  v-if="otpDeviceList.otpDeviceList.length != 1" id="delete_icon" name="delete_icon"><img class="delete-icon-size"
                    src="@/common/assets/styles/corp/images/delete_bin.png" /></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <DeleteOTPComponent :deviceToBeDelete="deviceToBeDelete" :delSelectedDevice="delSelectedDevice"
         :deviceTypeToBeDelete="deviceTypeToBeDelete" :uuID="uuID"></DeleteOTPComponent> -->
          <button type="submit" :disabled="isButtonDisabled" :class="{ 'disabled' : isButtonDisabled }" 
              @click="callStepUP('addotpdevice')" class="btn vbg-ff-btn-cancel btn_add_del vbg-ff-btn-width140 spacing05" id="add_delivery_method_button" name="add_delivery_method_button"
            >
      <span>Add delivery method</span>
    </button>

    <!-- <hr class="hr-bar marbottom20 mt-0" />
      <div class="col-xs-6 padleft0 returnProfileBottom">
        <button class="btn primary_black martop60 marbottom20" @click="redirect()">Return to Profile</button>
      </div> -->
  </div>


</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  // import MessagesComponent from '@/profile/components/core/MessagesComponent'
  import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
  import commonMixin from '@/common/mixin/commonMixins'
  // import DeleteOTPComponent from '@/profile/components/postauth/DeleteOTPComponent';
  import translations from "@/common/assets/json/messages1.json";
  export default {
    name: "OverviewComponent",
    mixins: [commonMixin],
    data() {
      return {
        delSelectedDevice: "",
        deviceToBeDelete:"",
        deviceTypeToBeDelete: "",
        uuid: "",
        modalShown: false,
        targetUrl:"",
        whichRealm: true,
        max_devices_limit_msg: translations.en.message['reset_security_profile_otp_summary_max_devices'],

      }
    },
    async created() {
      setPageDetails('Profile:Overview'); 
    },
    methods: {
      ...mapActions('cvsstepup',[
        'updateUsernameForStepup',
        'updateFlowType',
        'initializeStepup'
    ]),
      ...mapActions("profile", [
        'getUserProfile',
        'deleteOTPDevice',
        'OttSetUP',
        'resetMessages',
        'updateTargetUrl',
        'updateUrlOnMount'
        

      ]),
      
      redirect:function(){
        let sanitizedUrl = this.sanitizeUrl(this.logOutGotoUrl);
        window.location.href = sanitizedUrl != null ? this.logOutGotoUrl : this.sanitizeUrl(this.urlOnMount);
      },
      moveover(){
       
        $('#passcodeInfo').popover({ trigger: "hover focus" })
        $('#statusInfo').popover({ trigger: "hover focus" })
     },
     deleteDevice(item) {
        this.deviceToBeDelete = item.otp_value;
        this.delSelectedDevice = item.guid;
        this.deviceTypeToBeDelete = item.otp_type.toLowerCase();
        // window.scrollTo(0,0);
        // $("#modal-popup").modal({
        //   backdrop: "static",
        // });
        window.sessionStorage.setItem('deviceToBeDelete',item.otp_value)
        window.sessionStorage.setItem('delSelectedDevice',item.guid)
        window.sessionStorage.setItem('deviceTypeToBeDelete',item.otp_type.toLowerCase())
        window.sessionStorage.setItem('uuid',this.uuID)
        this.callStepUP("deleteOTP")
    },
   
    callStepUP(attribute){
        this.resetMessages()
        let payload={
          attrUpdated:attribute,
          userName:this.userName
        }
        this.OttSetUP(payload)
        sessionStorage.setItem('optSelected', attribute)
      
    },
    parseUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const targetquery = urlParams.get('target');
      const fragment = window.location.hash.substring(1);
      const targetParam = targetquery+'#'+ fragment;
      this.updateTargetUrl(targetParam)
    },

    },
    mounted() {
// URLSearchParams not supported in IE
 // let params = new URLSearchParams(window.location.search)
      if(this.getParameterByName('stepupVerification')) {
        this.$store.commit('profile/setStepupVerified', true)
      }else{
        
        this.$store.commit('profile/setStepupVerified', false)
      }
      this.parseUrl();
      this.getUserProfile(this.logOutGotoUrl)

      if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          this.whichRealm = false;
        }
      }
      this.changeContextBiocatch('postauth_overview_page')
      this.updateUrlOnMount(window.location.href);
    },
    created () {
      
    },
    components: {
      // MessagesComponent,
      // DeleteOTPComponent
    },

    computed:{
    ...mapGetters("profile", [
 
    'emailAddress',
    'userName',
    'firstName',
    'lastName',
    'loginLastTime',
    'otpDeviceList',
    'sqa',
    'uuID',
    'infoMsg',
    'showinfoMsg',
    'profileRedirectUrl',
    'stepupVerified',
    'logOutGotoUrl',
    'urlOnMount'
  ]),
      ...mapGetters('cvsstepup', [
      'userValidationError',
      'currentStep',
      'cannotProceedRecovery',
      'flowType',
    ]),
    isButtonDisabled() {
      if(this.otpDeviceList && this.otpDeviceList.otpDeviceList){
        return this.otpDeviceList.otpDeviceList.length == 5;
      } else {
        return false
      }
        
    },
     
  }
};
</script>

<style scoped>
.overView-Title{
  margin-bottom: 24px;
  color: #000;
}
.security-details {
  margin-top: 48px;
  padding-left: 0px;
}
.caret-position {
  margin-bottom: -1px;
  height: 10px;
  width: 5px;
}
.pwd-hr-position {
  margin-bottom: 16px;
  margin-top: 0px;
}
.chg-pwd-pos {
  margin-bottom: 32px;
}
.hr-pos-24 {
  margin-bottom: 24px;
  margin-top: 0px;
}
.hr-pos-12 {
  margin-bottom: 12px;
}
.device-type-pos {
  margin-left: 8px;
}

.btn-breadcrumb-back {
  color: #000000;
  font-family: VzNHGeDS-bold11;
  font-size: 16px;
}
.ovrview-msg {
  padding-bottom: 24px;
  max-width: 914px;
}
.msg-icon-position {
  padding: 0px;
  margin-right: 12px;
}
.messageContentUn {
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 0px;
  margin: 0px;
  color: #ffffff
}

.overViewTitle-text {
  color: #000000;
  font-family: VzNHGeDS-bold11;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 8px;
}
.btn_chng_pwd{
  /* width: 200px; */
  height: 44px;
  margin-bottom: 48px;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}
.btn_add_del{
  height: 44px;
  margin-top: 32px;
  margin-bottom: 32px;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}

.current-secret-quest {
  height: 16px;
  width: 149px;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.overview-grid-layout {
  display: grid;
}

.devices-table {
  width: 596px;
}
.vbg-rsp-device-table-delete-icon {
  margin-left: 15px;
}
  .textDesc,
  .edit-link {
    display: inline-block;
    padding-left: 0px;
  }

  td,
  th {
    border: 0px;
    text-align: left;
    padding: 8px;
  }
  .tb-profile{
    width: 100%;
    margin-bottom:50px
  }
  .tb-profile td{
    margin-right: 50px;
  }
.view-otp-table{
  width: 100%;
}

.vbg-ff-btn-cancel {
  float: none !important;
}
.returnProfileBottom {
  width: 100% !important;
  padding-right: 0 !important;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  padding: 12px;
  font-size: 16px;
}
h2{
  color: #000 !important;
}
@media  (max-width: 1024px){
  .returnProfile {
    display: none;
  }
  .returnProfileBottom button {
    /*width: 47% !important;*/
    margin-bottom: 50px !important;
  }
}
@media (min-width: 1025px) {
  .returnProfileBottom {
    display: none;
  }
}
@media (max-width: 767px) {
  .returnProfileBottom button {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .returnProfileBottom button {
    width: 47%;
  }
}
.user-details-container {
  display: flex;
  justify-content: space-between;
  margin-left: 0 !important;
  margin-right: 0 !important;
  /*padding-left: 15px;*/
}
.user-details-first-container {
  display: flex;
  justify-content: space-between;
  flex: 0.8;
}
.user-details-first-container div{
  margin-right: 20px;
}
@media  (min-width: 425px){
  .user-details-container {
    justify-content: flex-start;
  }
}
@media  (max-width: 768px){
  .user-details-container {
    flex-direction: column;
    /*padding-left: 15px;*/
  }
  .oauth-flow-ml0 {
    margin-left: 0px !important;
  }
  .user-details-first-container {
    flex-direction: column;
  }
}
@media (min-width: 769px){
  .user-details-container {
    border-top: 4px solid #000;
    padding-top: 24px;
    margin-bottom: 48px;
  }

}

thead.table-head.para2 {
  border-top: 0px !important;
}
table { border-collapse: collapse; border-spacing: 0; }
.oauth-hr8 {
  height: 8px !important;
}
.table_text {
  color: #000;
  font-size: 16px;
}

@media  (max-width: 768px){
  .user-details-first-container div {
    margin-bottom: 24px;
  }
  .overViewTitle-text {
    font-size: 16px;
  }
  .overView-Title {
    font-size: 24px;
  }
  .hr-bar{
    background-color: #D8DADA;
  }
  .pwd-hr-position {
    margin-bottom: 23.5px;
  }
  .btn_chng_pwd {
    margin-bottom: 23.5px;
  }
  .btn.vbg-ff-btn-cancel {
    margin-bottom: 23.5px;
  }
  h2{
    font-size: 20px;
  }
  .hr-pos-12 {
  margin-bottom: 8px;
}
.change-pass-text {
    margin-bottom: 24px;
  }
  
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .btn.vbg-ff-btn-cancel {
    margin-bottom: 48px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 1024px) {
  .breadcrumb-desktop{
    display: none;
  }
  .breadcrumb-mobile{
    display: contents;
  }
  .devices-table {
    width: 100%;
  }
  .security-details {
    margin-top: 32px;
  }
}
@media (min-width: 1024px) {
  .breadcrumb-desktop{
    display: contents;
  }
  .breadcrumb-mobile{
    display: none;
  }
  .btn_chng_pwd{
    width: 200px;
  }
  .btn_add_del {
    width: 220px;
  }
}

@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
}
@media (max-width: 482px) {
  .msg-icon-position {
    padding-bottom: 60px !important;
  }
}
</style>
