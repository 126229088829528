import { playrest,cvsplayrest,iamPostAuthPlayrest } from '@/vbgauth/util/playrest-axios.js'
import { amrest } from '@/vbgauth/util/amrest-axios.js'
import translations from '@/common/assets/json/messages1.json'
import {iamvar_newServerUrl, iamvar_vbgcCookieName, iamvar_vbgcCookieExpiryInMins, iamvar_serverUrl} from '../../../../public/const.js'
import stepUpConfig from '@/common/assets/json/stepup_config.json'
import { getDeviceFingerprintInfoWithoutGeolocation } from '../../../../public/vbgIamDeviceFP.js'
// import {logger} from '../../../../public/logger.js'
import {errorSet, eventTracking} from  '../../../../public/adobeTagging.js';
import oldRouter from '@/vbgauth/router';
import newRouter from '@/vbgauth/router/newIndex.js';
import mixin from '@/common/mixin/commonMixins.js'
const postAuthMethod = sessionStorage.getItem('optSelected')
const router = pwd_less_new_login &&  !postAuthMethod ? newRouter : oldRouter
let docHTML = document.getElementsByTagName('html');

const getHostName = (url) => {
  var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
  return match[2];
  }
  else {
      return null;
  }
}
const getParameterByNameURL = (urlName, url) => {
  //mixin.methods.getParameterByNameURL
  urlName = urlName.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + urlName + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default {
    namespaced: true,
    state: {
      submitLoader: false,
      showTlsWarn: true,
      otpDevices: [],
      userQuestions: [],
      selectedOtpDevice: {},
      selectedQuestion: {},
      dynamicClasses: [{otpClass : 'chosen'},{otpClass : ''},{otpClass : ''},{otpClass : ''},{otpClass : ''}],
      dynamicErrorClasses: {
        "validateOtpValue" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "validateOtpCode" : { fieldClass : "form-control" , fieldErrorMsg : ""},
        "acctUid" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "acctPw" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "secProfQue" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "secProfOtpValue" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "secProfOtpCode" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "otpCode" : { fieldClass : "form-control" , fieldErrorMsg : ""},
        "sqa": { fieldClass : "form-control" , fieldErrorMsg : "" }
      },
      options: {	
        headers: {	
            'Accept-API-Version': 'resource=2.0',	
            'protocol': '1.0'	
        }	
      },
      otpSent: false,
      validOtpForm: false,
      fingerprintSaved: false,
      unableToReceiveOtpDialog: false,
      sqaDisabled: false,
      isPwReset: false,
      sqaAlerts: [],
      otpAlerts: [],
      fuAlerts: [],
      fpAlerts: [],
      wirelessAlerts: [],
      sqaSubmitLinkClass: false,
      messages: translations,
      otpDialogMsg: '',
      otpDialogTitle: '',
      stateInitialized: false,
      flowInitialized: null,
      disableSqaFields: false,
      stepupReason: null,
      aaThrottled: '',
      dialog: false,
      transactionId: null,
      forgotPwdSqaTransactionId: null,
      vzigInstance: null,
      username: null,
      firstname: null,
      lastname: null,
      email: null,
      captchaText: null,
      encryptedResp: null,
      account: null,
      mtn: null,
      PRE_INITIALIZE: "PRE_INITIALIZE",
      VZIGINSTANCE_CREATED: "VZIGINSTANCE_CREATED",
      FLOW_INITIALIZED: "FLOW_INITIALIZED",
      TOKEN_VALIDATED: "TOKEN_VALIDATED",
      TOKEN_ACCEPTED: "TOKEN_ACCEPTED",
      TOKEN_DENIED: "TOKEN_DENIED",
      TOKEN_EXPIRED:  "TOKEN_EXPIRED",
      TOKEN_CANCELLED: "TOKEN_CANCELLED",
      OTP_OPTIONS_DISPLAYED: "OTP_OPTIONS_DISPLAYED",
      SQA_DISPLAYED: "SQA_DISPLAYED",
      SECURITY_QUESTION_DISPLAYED: "SECURITY_QUESTION_DISPLAYED",
      START_VERIFICATION: "START_VERIFICATION",
      CANCEL_VERIFICATION: "CANCEL_VERIFICATION",
      CANCELED_VERIFICATION: "CANCELED_VERIFICATION",
      OTP_DELIVERED: "OTP_DELIVERED",
      FLOW_COMPLETED: "FLOW_COMPLETED",
      OTP_FAILED_TO_DELIVER: "OTP_FAILED_TO_DELIVER",
      OTP_VALIDATED: "OTP_VALIDATED",
      OTP_INVALIDATED: "OTP_INVALIDATED",
      OTP_EXPIRED: "OTP_EXPIRED",
      SQA_VALIDATED: "SQA_VALIDATED",
      SQA_INVALIDATED: "SQA_INVALIDATED",
      IFG_TOKEN_EXPIRED: "IFG_TOKEN_EXPIRED",
      IFG_TOKEN_PROCESS_ERROR: "IFG_TOKEN_PROCESS_ERROR",
      IFG_TOKEN_INVALID_REQUEST_ERROR:"IFG_TOKEN_INVALID_REQUEST_ERROR",
      currentStep: null,
      flowType: 'auth',
      userValidationError: false,
      userValidationContactAdminError: false,
      sqaEnabled: true,
      otpEnabled: true,
      noSqa: false,
      registerDevice: null,
      sqaValidationError: false,
      otpValidationError: false,
      verificationMode: null,
      oldDevice: null,
      resendDevice: {},
      otpPageMessages: {},
      sqaPageMessages: {},
      cannotProceedRecovery: false,
      vbgc: null,	
      gotoUrl: null,
      sessionCookie: null,
      urlOnMount: null,
      userAgent: null,
      resendPasscodeToSelectedDevice: false,
      verificationSent: true,
      resendVerificationToSelectedDevice: false,
      cantrcvLink:false,
      cannotRecoverUsername: false,
      otpDeliveryMsg: "",
      hideOtpValidateComponent: true,
      continueUrl: null,
      pageTimeout: false,
      otpAlwaysOn: false,
      recaptchaResponse: null,
      otpDeviceBlockEvent: false,
      sanitizedPayload: { },
      wirelessObject: null,
      isMobilenumberValid: true,
      isShowWirelessNumber: false,
      wirelessMob: null,
      vbgFlow: true,
      secureOtpPin: null,
      pwdInlineError: '',
      isSmbUser: false,
      vbgTranscationId:null,
      cannotProceedRecovery: false,
      userIdFlow: 'BASIC',
      vzottCookie: null,
      userDeniedOTP: false,
      tokenizedFlow: false,
      emailDeniedNotification: false,
      basicDeniedNotification: false,
      cvsAuthAlert: null,
      dnrStatus: true,
      deviceInfo: '',
      defaultSelectedDevice: {
        deviceID:"select-label",
        deviceValue: "",
        deviceType:"select",
        label:"Select",
        deviceEnable:false,
      },
      previousOtpTransactionID: null,
      initialRouteToken: '',
      showChangeError: false,
      maxAttemptError: false,
      clearInputFields: false,
      ffSQAEnabled: false,
      triggerSendOTP: false,
      sqaValidated: false,
      verification_canceled: 'verification_canceled',
      formatVcgSecretQuestion: '',
      vpsUsersData:null,
      vpsSuccessPageEmail: '',
      changedSelectedOption: false,
      pollingLoader: false,
      showMtnDeliveryMethod: false,
      skipOption: null,
      selectedDeviceIndex: null,
      isLoginChatbot:false,
      getTransactionDeliveryMethods: null,
      otpErrFlag: false,
      cancelPolling: false,
    },
    mutations: {
      setLoginChatbot(state, payload){
        state.isLoginChatbot = payload
      },
      setChangedSelectedOption(state,payload){
        state.changedSelectedOption = payload;
      },
      setSkipOption(state, payload){
        state.skipOption = payload
      },
      setOtpDevices(state, payload) {
        state.otpDevices = payload
      },
      setUserQuestions(state, payload) {
        state.userQuestions = [payload]
      },
      setSelectedQuestion(state) {
        if(state.userQuestions.length > 0)
          state.selectedQuestion = state.userQuestions[0]
      },
      setLoader(state, payload) {
        state.submitLoader = payload
      },
      setOtpSent(state, payload) {
        state.otpSent = payload
      },
      setValidOtpForm(state, payload) {
        state.validOtpForm = payload
      },
      setUnableToReceiveOtpDialog(state, payload) {
        state.unableToReceiveOtpDialog = payload
      },
      setSQAAlert(state, payload) {
        state.sqaAlerts = payload
      },
      setOTPAlert(state, payload) {
        state.otpAlerts = payload
      },
      setFuAlert(state, payload) {
        state.fuAlerts = payload
      },
      setFpAlert(state, payload) {
        state.fpAlerts = payload
      },
      setWirelessAlerts(state, payload) {
        state.wirelessAlerts = payload
      },
      setIsMobilenumberValid(state, payload) {
        state.isMobilenumberValid = payload
      },
      setIsShowWirelessNumber(state, payload) {
        state.isShowWirelessNumber = payload
      },
      setSendMDN(state, payload) {
        state.wirelessMob = payload
      },
      setFingerprintSaved(state, payload) {
        state.fingerprintSaved = payload
      },
      setOtpDialogMsg(state, payload) {
        state.otpDialogMsg = payload
      },
      setOtpDialogTitle(state, payload) {
        state.otpDialogTitle = payload
      },
      setStateInitialized(state, payload) {
        state.stateInitialized = payload
      },
      setDisableSqaFields(state, payload) {
        state.disableSqaFields = payload
      },
      setFlowInitialized(state, payload) {
        state.flowInitialized = payload
      },
      setStepupReason(state, payload) {
        state.stepupReason = payload
      },
      setAaThrottled(state, payload) {
        state.aaThrottled = payload
      },
      setShowDialog(state, payload) {
        state.dialog = payload
      },
      enableSQAOption(state, payload) {
        state.sqaEnabled = payload
      },
      enableOTPOption(state, payload) {
        state.otpEnabled = payload
      },
      setNoSqaReg(state, payload) {
        state.noSqa = payload
      },
      setTransactionId(state,payload) {
        state.transactionId = payload
      },
      setForgotPwdSqaTransactionId(state, payload) {
        state.forgotPwdSqaTransactionId = payload
      },
      createVZIGInstance( state, payload) {
        state.vzigInstance = new VZIGCVSCore(payload)
      },
      setUsername(state, payload) {
        state.username = payload;
      },
      setCurrentStep(state, payload) {
        state.currentStep = payload
      },
      setFlowType(state, payload) {
        state.flowType = payload
      },
      setUserFirstname(state, payload) {
        state.firstname = payload
      },
      setUserLastname(state, payload) {
        state.lastname = payload
      },
      setUserEmail(state, payload) {
        state.email = payload
      },
      setUserAccount(state, payload) {
        state.account = payload
      },
      setUserMtn(state, payload) {
        state.mtn = payload
      },
      setCaptchaText(state, payload) {
        state.captchaText = payload
      },
      setEncryptedResp(state, payload) {
        state.encryptedResp = payload
      },
      setUserValidationError(state, payload) {
        state.userValidationError = payload
      },
      setUserValidationContactAdminError(state, payload) {
        state.userValidationContactAdminError = payload
      },
      setUserDeviceRegistration(state, payload) {
        state.registerDevice = payload
      },
      setSqaValidationError(state, payload) {
        state.sqaValidationError = payload
      },
      setOtpValidationError(state, payload) {
        state.otpValidationError = payload
      },
      setVerificationMode(state, payload) {
        state.verificationMode = payload
      },
      setOldDevice(state, payload) {
        state.oldDevice = payload
      },
      setResendDevice(state, payload) {
        state.resendDevice = payload
      },
      setOtpDeviceBlockEvent(state, payload) {
        state.otpDeviceBlockEvent = payload
      },
      setStepupPageMessages(state, payload) {
        if(payload.cvs_otp) {
          state.otpPageMessages = {
            'title': state.messages.en.message[payload.cvs_otp.page_title_i18n_key],
            'subtitle': state.messages.en.message[payload.cvs_otp.page_subtitle_i18n_key],
            'desc': state.messages.en.message[payload.cvs_otp.page_desc_i18n_key],
            'instrn': state.messages.en.message[payload.cvs_otp.page_instrn_i18n_key],
            'idm_instrn': state.messages.en.message[payload.cvs_otp.idm_page_instrn_i18n_key]
          }
          if(state.flowType === 'POST_AUTH_FLOW') {
            if(postAuthMethod === 'modifypassword') {
              state.otpPageMessages['title'] = state.messages.en.message[payload.cvs_otp.pd_page_title_i18n_key]
            } else if(postAuthMethod === 'modifysqa') {
              state.otpPageMessages['title'] = state.messages.en.message[payload.cvs_otp.sqa_page_title_i18n_key]
            } else if(postAuthMethod === 'addotpdevice') {
              state.otpPageMessages['title'] = state.messages.en.message[payload.cvs_otp.addOTP_page_title_i18n_key]
            } else if(postAuthMethod === 'deleteOTP') {
              state.otpPageMessages['title'] = state.messages.en.message[payload.cvs_otp.deleteOTP_page_title_i18n_key]
            } 
          }
        }
        if(payload.cvs_sqa) {
          state.sqaPageMessages = {
            'title': state.messages.en.message[payload.cvs_sqa.page_title_i18n_key],
            'subtitle': state.messages.en.message[payload.cvs_sqa.page_subtitle_i18n_key],
            'desc': state.messages.en.message[payload.cvs_sqa.page_desc_i18n_key],
            'instrn': state.messages.en.message[payload.cvs_sqa.page_instrn_i18n_key]
          }
        }
        sessionStorage.removeItem('optSelected')
      },
      setCannotProceedRecovery (state, payload) {
        state.cannotProceedRecovery = payload
      },
      setVBGCCookieValue(state, payload) {	
        state.vbgc = payload	
      },	
      setGoto(state, payload) {	
        state.gotoUrl = payload	
      },
      setSessionCookie(state, payload) {
        state.sessionCookie = payload
      },
      setUrlOnMount(state, payload) {
        state.urlOnMount = payload
      },
      setUserAgent(state, payload) {
        state.userAgent = payload
      },
      setResendPasscode(state, payload) {
        state.resendPasscodeToSelectedDevice = payload
      },
      setResendVerification(state, payload) {
        state.resendVerificationToSelectedDevice = payload
      },
      setcantrcvLink(state, payload) {
        state.cantrcvLink = payload
      },
      setVerificationSent(state, payload) {
        state.verificationSent = payload
      },
      setCannotRecoverUsername(state, payload) {
        state.cannotRecoverUsername = payload
      },
      setOTPDeliveryMsg(state, payload) {
        state.otpDeliveryMsg = payload
      },
      setHideOtpValidateComponent(state, payload) {
        state.hideOtpValidateComponent=payload
      },
      setContinueUrl(state, payload) {
        state.continueUrl = payload
      },
      displayPageTimeout(state, payload) {
        state.pageTimeout = payload
      },
      setOTPAlwaysOn(state, payload) {
        state.otpAlwaysOn = payload
      },
      setRecaptchaResponse(state, payload) {
        state.recaptchaResponse = payload
      },
      setSanitizedPayload(state, payload) {
        state.sanitizedPayload = payload
      },
      setWirelessObject(state, payload) {
        state.wirelessObject = payload
      },

      setVbgFlow(state, payload){
        state.vbgFlow = payload
      },
      setPwdInlineError(state, payload) {
        state.pwdInlineError = payload
      },
      setIsSmbUser(state, payload){
        state.isSmbUser = payload
      },

     setVbgTranscationId(state, payload){
        state.vbgTranscationId = payload
      },
      setUserIdFlow(state, payload) {
        state.userIdFlow = payload
      },
      setVzottCookie(state, payload) {
        state.vzottCookie = payload
      },
      setUserDeniedOTP(state, payload) {
        state.userDeniedOTP = payload
      },
      setTokenizedFlow(state, payload) {
        state.tokenizedFlow = payload
      },
      setEmailDeniedNotification(state, payload) {
        state.emailDeniedNotification = payload
      },
      setBasicDeniedNotification(state, payload) {
        state.basicDeniedNotification = payload
      },
      setCvsAuthAlert(state, payload) {
        state.cvsAuthAlert = payload
      },
      setDnrStatus(state, payload) {
        state.dnrStatus = payload
      },
      setDeviceInfo(state, payload) {
        state.deviceInfo = payload
      },
      setPreviousOtpTransactionID(state, payload) {
        state.previousOtpTransactionID = payload
      },
      setInitialRouteToken(state, payload) {
        state.initialRouteToken = payload
      },
      setShowChangeError(state, payload) {
        state.showChangeError = payload
      },
      setMaxAttemptError(state, payload) {
        state.maxAttemptError = payload
      },
      setClearInputFields(state, payload) {
        state.clearInputFields = payload
      },
      enableFFSQAoption(state, payload) {
        state.ffSQAEnabled = payload
      },
      setTriggerSendOTP(state, payload) {
        state.triggerSendOTP = payload
      },
      setSQAValidated(state, payload) {
        state.sqaValidated = payload
      },
      setFormatVcgSecretQuestion(state, payload) {
        state.formatVcgSecretQuestion = payload
      },
      setVpsUsersData(state, payload){
        state.vpsUsersData = payload
      },
      setVpsSuccessPageEmail(state, payload){
        state.vpsSuccessPageEmail = payload
      },
      setPollingLoader(state, payload) {
        state.pollingLoader = payload
      },
      setShowMtnDeliveryMethod(state, payload) {
        state.showMtnDeliveryMethod = payload
      },
      setSelectedDeviceIndex(state, payload) {
        state.selectedDeviceIndex = payload
      },
      setTransactionDeliveryMethods(state, payload) {
        state.getTransactionDeliveryMethods = payload
      },
      setOTPErrFlag(state, payload) {
        state.otpErrFlag = payload
      },
      setCancelPolling(state, payload) {
        state.allowPolling = payload
      }
    },
    actions: {
      resetState({commit, state}) {
        commit('setOTPAlert', [])
        commit('setSQAAlert', [])
        commit('setFuAlert', [])
        commit('setWirelessAlerts',[])
        commit('setFingerprintSaved', false)
        commit('setUserValidationError', false)
        commit('setUserValidationContactAdminError', false)
        commit('setClearInputFields', false)
        state.currentStep = null
        state.otpSent = false
      },
      setSelectedOtpDevice({state, dispatch}, payload) {
        if(payload) {
          state.otpSent = false
          state.selectedOtpDevice = payload
        } else if(state.oldDevice) {
          var changedDevice = state.otpDevices.filter(x => x.label === state.oldDevice.label)[0]
          if(changedDevice.deviceID != state.oldDevice.deviceID || changedDevice.label === "Enter Wireless Number") {
            state.selectedOtpDevice = changedDevice
            if(state.flowType != "forgot_password") {
            state.oldDevice = null
            }
            state.otpAlerts = []
            state.wirelessAlerts = []
            state.otpSent = false
            if (state.resendPasscodeToSelectedDevice) {
              dispatch('updateResendVerificationToSelectedDevice', true)
              dispatch('sendOtpToDevice', state.selectedOtpDevice.deviceID)
              dispatch('updateResendPasscodeToSelectedDevice', false)
            }
          } else {
            dispatch('setSelectedOtpDevice', state.selectedOtpDevice)
          }
        } else if (state.otpDevices.length > 0)
            state.selectedOtpDevice = state.otpDevices[0]
          else {
            state.selectedOtpDevice = { }
          }
          if(state.wirelessObject && state.wirelessObject.deviceValue == '***-***-LDER' && state.selectedOtpDevice.deviceID == state.wirelessObject.deviceID) {
            dispatch('showWirelessNumber', true)
            dispatch('sendMDN',state.wirelessMob)
          }
      },
      updateUsernameForStepup({commit}, payload) {
        commit('setUsername', payload)
      },
      updateInlineError({ commit }, payload) {
        commit('setPwdInlineError', payload)
      },
      updateUserDetailsForStepup({commit}, payload) {
        if(payload.firstname) {
          commit('setUserFirstname', payload.firstname)
        }
        if(payload.lastname) {
          commit('setUserLastname', payload.lastname)
        }
        if(payload.email) {
          commit('setUserEmail', payload.email)
        }
        if(payload.account) {
          commit('setUserAccount', payload.account)
        } else { commit('setUserAccount', null) }
        if(payload.mtn) {
          commit('setUserMtn', payload.mtn)
        } else { commit('setUserMtn', null) }
      },
      updateCaptchaText({commit}, payload) {
        commit('setCaptchaText', payload)
      },
      updateEncryptedResp({commit}, payload) {
        commit('setEncryptedResp', payload)
      },
      updateFlowType({commit}, payload) {
        commit('setFlowType', payload)
      },
      updateUrlOnMount({commit}, payload) {
        commit('setUrlOnMount', payload)
      },
      updateUserAgent({commit}, payload) {
        commit('setUserAgent', payload)
      },
      igCaptchaEndpoint({commit, state, dispatch}, optionalChallenge) {
        commit('setLoader', true)
        commit('setOTPAlert', [])
        commit('setSQAAlert', [])
        commit('setOtpSent', false)
        commit('setWirelessAlerts', [])
        commit('setUserValidationError', false)
        commit('setUserValidationContactAdminError', false)
        var payload = {}
        var requestSettings = { headers: { } }
        if(state.flowType === 'forgot_password') {
          // logger.info('The user submitted his details to recover password')
          payload.requestType = 'FORGOT_PASSWORD_FLOW'
          payload.userName = state.username
          payload.captchaText = state.captchaText
          if(optionalChallenge.challengeMethod == 'SQA') {
            if(state.urlOnMount !== null && state.urlOnMount.includes('/account/business/login/mobile')) {
              //sending deviceInfo from X-VZ-DFP as a payload in get transaction call when user is coming from /mobile
              payload.deviceInfo = state.deviceInfo ? state.deviceInfo : ''
            } else {
              //sending deviceFingerprint as a payload in get transaction call when user is coming from urls apart from /mobile
              payload.deviceInfo = getDeviceFingerprintInfoWithoutGeolocation().value
              dispatch('updateSetDeviceInfo',payload.deviceInfo)
            }
          }
        } else if(state.flowType === 'forgot_username') {
          // logger.info('The user submitted his details to recover username')
          payload.requestType = 'FORGOT_USERNAME_FLOW'
          payload.email = state.email
          payload.captchaText = state.captchaText
        }
        if (state.flowType === 'forgot_username' || state.flowType === 'forgot_password') {
          let igCaptchaApiClient = axios.create({
            baseURL: iamvar_serverUrl
          });
          return igCaptchaApiClient.post('ifgservices/vbg/get-captcha', payload).then(function (response) {
            if (response) {
              commit('setEncryptedResp', response.data)
              commit('setLoader', false)
              dispatch('initializeStepup', optionalChallenge)
            }
          }).catch((error) => {
            var errorData = error.response
            commit('setLoader', false)
            if(errorData.status === 409) {
              if(state.flowType === 'forgot_username') {
                if(errorData.data === 'INVALID_CAPTCHA') {
                  var msg1 = [ {	msg : state.messages.en.message['forgot_flows_jcaptcha_invalid'],type : 'error'} ]
                  errorSet(state.messages.en.message['forgot_flows_jcaptcha_invalid']);
                  commit('setFuAlert', msg1)
                  commit('setClearInputFields', true)
                }
              } else if(state.flowType === 'forgot_password') {
                if(errorData.data === 'INVALID_CAPTCHA') {
                  var msg5 = [ {	msg : state.messages.en.message['forgot_flows_jcaptcha_invalid'],type : 'error'} ]
                  errorSet(state.messages.en.message['forgot_flows_jcaptcha_invalid']);
                  commit('setFpAlert', msg5)
                  commit('setClearInputFields', true)
                }
              }
            }
            else {
              if(state.flowType === 'forgot_username') {
                commit('setFuAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
              } else if(state.flowType === 'forgot_password') { 
                commit('setFpAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
              }
            }
          })
        }
        commit('setLoader', false)
      },
      vpsTriggerForgotuserNameEmail({commit, state, dispatch}, userName){
        var payload = {
          'UserName': userName
        }
        commit('setLoader', true)
        return playrest.post('/vps/forgotuid', payload).then(function (response) {
          if(response.data.responseCode == '00') {
            router.push({ name: 'VPSForgotUserIdEmailSent' });
          } else if(response.data.responseCode == '01') {
            commit('setFuAlert', [{ msg: state.messages.en.message['bckend_unknown_err'], type: 'error' }])
          }
          commit('setLoader', false)
        }).catch((error) => {
          // generic network error
          commit('setFuAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
          commit('setLoader', false)
          throw error
        })
      },
      // OTP and SQA components send the challengeMethod on post authentication stepup
      initializeStepup({commit, state, dispatch}, optionalChallenge) {
        commit('setUserDeniedOTP', false);
        commit('setLoader', true)
        commit('setOTPAlert', [])
        commit('setSQAAlert', [])
        commit('setOtpSent', false)
        commit('setWirelessAlerts', [])
        commit('setUserValidationError', false)
        commit('setUserValidationContactAdminError', false)
        dispatch('updateDnrStatus', false)
        commit('setChangedSelectedOption', false)
        commit('setTransactionDeliveryMethods', null)
        commit('setOTPErrFlag', false)
        var payload = {}
        // var otpErrFlag = false ;
        var requestSettings = { headers: { } }
        if(state.flowType === 'forgot_password') {
          // logger.info('The user submitted his details to recover password')
          payload.requestType = 'FORGOT_PASSWORD_FLOW'
          payload.userName = state.username
          payload.captchaText = state.captchaText
          payload.userInfo = state.encryptedResp
          //This payload is only applicable for the first get transaction call of forgot password flow
          // || condition is there in case of resend passcode if sqa page is not there thn we need to send deviceInfo again
          if((optionalChallenge && optionalChallenge.challengeMethod == 'SQA') || state.forgotPwdSqaTransactionId == null) {
            payload.deviceInfo = state.deviceInfo ? state.deviceInfo : ''
          }
          if(state.forgotPwdSqaTransactionId != null) {
            payload.previousTransactionID = state.forgotPwdSqaTransactionId
            payload.vbgFlow = state.vbgFlow
          }
          // second condition is added as part of enhanced forgot password. We don't need to send SQA as challenge method in first get transaction call
          if(optionalChallenge != null) {
            if(optionalChallenge.challengeMethod != 'SQA') {
              payload.challengeMethod = optionalChallenge.challengeMethod
            }
          } else { // default to OTP
            // in case of resend passcode if sqa page is not there then challengeMethod shouldn't be there
            if(state.forgotPwdSqaTransactionId != null) {
              payload.challengeMethod = 'OTP'
            }
          }
          if(!state.dnrStatus && state.previousOtpTransactionID != null) {
            payload.previousOtpTransactionID = state.previousOtpTransactionID;
          }
        } else if(state.flowType === 'forgot_username') {
          // logger.info('The user submitted his details to recover username')
          payload.requestType = 'FORGOT_USERNAME_FLOW'
          if(state.userIdFlow == 'BASIC') {
            payload.email = state.email
            payload.captchaText = state.captchaText
            payload.userInfo = state.encryptedResp
            if(optionalChallenge && optionalChallenge.isVPSFlow && optionalChallenge.isVPSFlow === true) {
              payload.isVpsUserFlow = true;
              commit('setVpsSuccessPageEmail', state.email);
            }
          } else if(state.userIdFlow == 'SIMPLE') {
            payload.captchaText = state.captchaText
            payload.firstName = state.firstname
            payload.lastName = state.lastname
            payload.userIdFlow = 'SIMPLE'
          } else if(state.userIdFlow == 'COMPLEX') {
            payload.captchaText = state.captchaText
            payload.firstName = state.firstname
            payload.lastName = state.lastname
            if(state.account) {
              payload.accNo = state.account
            }
            if(state.mtn) {
              payload.mtn = state.mtn
            }
            
            payload.userIdFlow = 'COMPLEX'
          }
        } else if(state.flowType === 'auth') {
          payload.requestType = 'AUTH_FLOW'
        } else if(state.flowType === 'POST_AUTH_FLOW') {
          //console.log('flowtype = post auth')

          // assign default challengeMethod (when user tries to resend passcode, challengeMethod would be null)
          if(optionalChallenge == null) {
            optionalChallenge = { challengeMethod: 'OTP' }
          }
          //console.log('from initializeStepup challenge method: ', optionalChallenge.challengeMethod)
          payload.requestType = 'POST_AUTH_FLOW'
          dispatch('getSessionCookie')
          
          payload.challengeMethod = optionalChallenge.challengeMethod
          //console.log('payload from post auth = ', payload)
          
          // set header settings
          // requestSettings.headers = { 'sessionCookie': state.sessionCookie }
          //console.log('header settings obj', requestSettings)
        } else if(state.flowType === 'auth_sqa_flow') {
          console.log("state.userName..",state.username)
          commit('setVbgFlow', false);
          payload.requestType = 'AUTH_SQA_FLOW'
          payload.userName = state.username
          payload.challengeMethod = "SQA"
        } else if(iamvar_loginAssistant && state.flowType === 'login_assistant') {
          payload.requestType = 'AUTH_UNLOCK_ACC_FLOW'
          payload.userName = state.username
        }

        let restEndpoint = iamvar_iamStepupServ + '/request-stepup/get-transaction'

        // append query parameter if recaptchaResponse is available
        if(state.recaptchaResponse != null) {
         // restEndpoint += '?g-recaptcha-response=' + state.recaptchaResponse
         requestSettings.headers = { 'recaptchatoken': state.recaptchaResponse }
        }
        if (localStorage.getItem('X-Vz-Gsa') != "undefined") {
          requestSettings.headers = { 'X-Vz-Gsa': localStorage.getItem('X-Vz-Gsa')}
        }
        if(state.userAgent != null) {
          requestSettings.headers = { 'X-VZ-USER_AGENT': state.userAgent }
        }
        if(state.vzottCookie != null) {
          requestSettings.headers = {'vzott': state.vzottCookie}
        }

        let restClient = playrest

        // change rest client when coming for post auth flow
        if(state.flowType === 'POST_AUTH_FLOW') {
          restClient = iamPostAuthPlayrest
        }
        if(login_assistant_unlockaccount_metrics && state.isLoginChatbot) payload.isLoginChatbot = true;
        dispatch('sanitizePayloadForLogging', payload);

        return restClient.post(restEndpoint, payload, requestSettings).then(function (response) {
          let navigateTouserdIdInfo = false
          if(iamvar_aaThrottleEnable){
            if(response.headers['x-vz-throttled'] != undefined){
              commit('setAaThrottled', response.headers['x-vz-throttled'])
            } else if(response.headers['X-VZ-THROTTLED'] != undefined){
              commit('setAaThrottled', response.headers['X-VZ-THROTTLED'])
            } else if(response.headers['X-Vz-Throttled'] != undefined) {
              commit('setAaThrottled', response.headers['X-Vz-Throttled'])
            }
          }
          if(optionalChallenge && optionalChallenge.isVPSFlow && optionalChallenge.isVPSFlow === true) {
            commit('setLoader', false);
            if(response.data){
              if(Object.keys(response.data).length === 1){
                commit('setVpsUsersData', response.data);
                commit('setCurrentStep',"vpssingleuser");
              } else {
                commit('setVpsUsersData', response.data);
                commit('setCurrentStep',"vpsmultiuser");
              }
            }
          }else{
          if(state.flowType === 'forgot_username') {
            if(response.data && response.data.userIdFlow != undefined) {
              navigateTouserdIdInfo = true
              dispatch('updateUserIdFlow', response.data.userIdFlow)
              commit('setFuAlert', [])
              dispatch('updateVzottCookie',response.headers['vzott'])
            }
          } 
          if(!navigateTouserdIdInfo) {
            commit('setTransactionId', response.data.transactionID)
            var transactionID = state.transactionId
            //console.log('transaction ID ', transactionID)
            //console.log("response.data.data..",response.data.data)
            if (response.data.vbgFlow != undefined) {
              commit('setVbgFlow', response.data.vbgFlow);
            // commit("setVbgFlow", false);
            }
            var vbgFlow = state.vbgFlow
            
            if(iamvar_aaTokenizedLinkToggle == true) {
              if ((response.data.isSmbUser != undefined) && (state.flowType === 'POST_AUTH_FLOW')) {
                commit('setIsSmbUser', response.data.isSmbUser)
              } else if ((response.data.isSmbUser != undefined) && (state.flowType === 'auth')) {
                commit('setIsSmbUser', response.data.isSmbUser)
              }
            } else if (iamvar_aaTokenizedLinkToggle == false) {
              if (response.data.isSmbUser != undefined) {
                commit('setIsSmbUser', response.data.isSmbUser)
              }
            }
            var isSmbUser = state.isSmbUser
            var fetchWirelessObj = response.data.data.listOfDevices.find((h)=> {
              return h.deviceValue == '***-***-LDER'
            })
            //console.log("listOfdevice..", response.data.data.listOfDevices.find((h)=> {
              //return h.deviceValue == '***-***-LDER'
            //}))
            //if(fetchWirelessObj) {
            if(state.vbgFlow) {
              if ((iamvar_pwdLessFlowType != 'complex') && (iamvar_pwdLessFlowType != 'basic')) { 
                if(state.isSmbUser) {
                  var createWirelessObj = {
                    deviceEnable: true,
                    deviceID: "1aabbccdd1-2ee2-3ff3-4gg4-5hhiijjkkll5",
                    deviceType: 'BASIC',
                    deviceValue: '***-***-LDER',
                    label: "Enter Wireless Number"
                  };
                  dispatch('wirelessObj',createWirelessObj)
                }
              }
            }
            var connType = 'POLLING'
            //console.log('new server url ', iamvar_newServerUrl)
            var igBackendUrl = iamvar_newServerUrl + iamvar_cvsApiRoute + iamvar_cvsStepupServ+'/2fa/polling'
            var otpDeliveryMsg1 = null;
            if(state.flowType == 'forgot_password') {
              commit('setForgotPwdSqaTransactionId', response.data.transactionID)
              if(response.data && response.data.DNR) {
                dispatch('updateDnrStatus',response.data.DNR)
              }
              if(!state.dnrStatus && state.previousOtpTransactionID == null) {
                commit('setPreviousOtpTransactionID',response.data.transactionID)
               // commit('setTransactionId', response.data.transactionID)
              }
              //  else if(!state.dnrStatus && state.previousOtpTransactionID != null) {
              //   commit('setTransactionId', response.data.transactionID)
              // }
            }
            var callBackFunction = function(resp) {
              //console.log(resp.responseMessage)
              //console.log(resp.responseCode)
              // commit('setLoader', pwd_less_new_login ? true : false)
              if(!state.vbgFlow){
                if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
                  if (iamvar_pwdLessFlowType != '') {
                    //commit('setHideOtpValidateComponent', false)
                  } else {
                    commit('setHideOtpValidateComponent', true)
                  }
                } 
              }
              if(resp.responseCode === "01" && !state.vbgFlow && max_attempt_toggle == true) {
                  dispatch('checkVerificationStatus')
              } else if(resp.responseCode === "02") {
                //console.log('Processing Error')
                if(!state.vbgFlow){
                  commit('setCurrentStep', state.IFG_TOKEN_PROCESS_ERROR)
                  msg = [{msg: state.messages.en.message["tok_otp_code_process_error"], type:'error'}]
                  errorSet(state.messages.en.message['tok_otp_code_process_error']);
                  commit('setOTPAlert', msg)
                }
                // logger.error('Error in processing request')
              } else if(resp.responseCode === "03") {
                if(!state.vbgFlow){
                  msg = [{msg: state.messages.en.message["tok_unable_to_send_sms"], type:'error'}]
                  errorSet(state.messages.en.message['tok_otp_expired_code_error']);
                  commit('setOTPAlert', msg)
               } else{
                commit('setCurrentStep', state.OTP_FAILED_TO_DELIVER)
                commit('setOtpSent', false)
                var msg = [{msg: state.messages.en.message["login_otp_send_error"], type:'danger'}];
                errorSet(state.messages.en.message["login_otp_send_error"]);
                commit('setOTPAlert', msg)
                commit('setHideOtpValidateComponent', true)
               }                
              } else if(resp.responseCode === "06") {
                // max attempts
                if(!state.vbgFlow && resp.responseMessage == "Max attempts reached"){
                  msg = [{msg: state.messages.en.message["max_attempt_reached"], type:'error'}]
                  errorSet(state.messages.en.message['max_attempt_reached']);
                  // Redirecting to this screen , when Delivery option method exceeds & empty device list
                  if(!state.otpDevices.filter(obj => obj.deviceType !== "select").length) {
                    state.flowType == 'auth_sqa_flow'? router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } }) : router.push({ path: '/login/deliveryMethodLocked' }) 
                  }
                  commit('setOTPAlert', msg)
                  dispatch('formatDevices', [])
                }
              } else if(resp.responseCode === "07") {
                // token expired
                if(!state.vbgFlow){
                  if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
                    commit('setCurrentStep', state.IFG_TOKEN_EXPIRED)
                    msg = [{msg: state.messages.en.message["tok_otp_expired_code_error"], type:'error'}]
                    errorSet(state.messages.en.message['tok_otp_expired_code_error']);
                    commit('setOTPAlert', msg)
                    // token expired reset dropdown state to default
                    dispatch('setResendSelectedDevice', state.selectedOtpDevice)
                    if(!pwd_less_new_login) dispatch('updateSelectedDevice', state.defaultSelectedDevice);
                  } else if((state.selectedOtpDevice.deviceType == 'BASIC') || (state.selectedOtpDevice.deviceType == 'IVR')) {
                    commit('setCurrentStep', state.TOKEN_EXPIRED)
                    msg = [{msg: state.messages.en.message["otp_expiry"], type:'error'}]
                    errorSet(state.messages.en.message['otp_expiry']);
                    commit('setOTPAlert', msg)
                  }
                }else{
                  commit('setCurrentStep', state.TOKEN_EXPIRED)
                  msg = [{msg: state.messages.en.message["otp_expiry"], type:'error'}]
                  errorSet(state.messages.en.message['otp_expiry']);
                  commit('setOTPAlert', msg)
                }
                // logger.warn('Transaction token expired')

              } else if(resp.responseCode === "09") {
                // invalid request
                if(!state.vbgFlow){
                  if(!state.showChangeError) {
                    if(state.emailDeniedNotification) {
                      commit('setEmailDeniedNotification', false)
                      msg = [{msg: state.messages.en.message["tok_otp_deny_code_error"], type:'error'}]
                      errorSet(state.messages.en.message['tok_otp_deny_code_error']);
                      commit('setOTPAlert', msg)
                    } else if(state.basicDeniedNotification) {
                      commit('setBasicDeniedNotification', false)
                      msg = [{msg: state.messages.en.message["reg_otp_verify_code_error"], type:'error'}]
                      errorSet(state.messages.en.message['reg_otp_verify_code_error']);
                      commit('setOTPAlert', msg) 
                    } else {
                      commit('setShowChangeError', false)
                      commit('setCurrentStep', state.IFG_TOKEN_PROCESS_ERROR)
                      msg = [{msg: state.messages.en.message["tok_otp_code_process_error"], type:'error'}]
                      errorSet(state.messages.en.message['tok_otp_code_process_error']);
                      commit('setOTPAlert', msg)
                    } 
                  }
                  if(state.maxAttemptError){
                    commit('setMaxAttemptError', false)
                    if(state.vbgFlow) {
                      msg = [{msg: state.messages.en.message["reg_otp_verify_code_maxattempts_error"], type:'error'}]
                      errorSet(state.messages.en.message['reg_otp_verify_code_maxattempts_error']);
                    } else {
                      msg = [{msg: state.messages.en.message["reg_otp_verify_code_maxinvalidattempts_error"], type:'error'}]
                      errorSet(state.messages.en.message['reg_otp_verify_code_maxinvalidattempts_error']);
                    }
                    commit('setOTPAlert', msg)
                  }
                }
              } else if(resp.responseCode === "10") {

              } else if(resp.responseCode === "11" || resp.responseCode === "12" || resp.responseCode === "13" || (resp.responseCode === "14" && !vbgFlow)) {
                commit('setOtpSent', true)
                commit('setCurrentStep', state.OTP_DELIVERED)
                // logger.info('OTP delivered to the user selected device')
                // state.seelctedOtpDevice.label format = EMAIL   e***@e***.com, 3 spaces in between
                let deviceTypeLabel = state.selectedOtpDevice.label.split(' ')
                if(state.vbgFlow && !otpErrFlag) {
                otpErrFlag = true;
                if (iamvar_pwdLessFlowType == '' && !pwd_less_new_login) {
                let otpSentMsg = state.messages.en.message["login_otp_"+state.selectedOtpDevice.deviceType+"_code_sent"].replace("$0", deviceTypeLabel[deviceTypeLabel.length - 1])
                commit('setOTPAlert', [ { msg: otpSentMsg, type: 'success' } ])
                }
                commit('setOTPDeliveryMsg', otpDeliveryMsg1)
                }
                if(!state.vbgFlow && !otpErrFlag && !pwd_less_new_login) {
                  if((state.selectedOtpDevice.deviceType == 'BASIC') || (state.selectedOtpDevice.deviceType == 'IVR')) {
                    otpErrFlag = true;
                    let otpSentMsg = state.messages.en.message["login_otp_"+state.selectedOtpDevice.deviceType+"_code_sent"].replace("$0", deviceTypeLabel[deviceTypeLabel.length - 1])
                    commit('setOTPAlert', [ { msg: otpSentMsg, type: 'success' } ])
                    commit('setOTPDeliveryMsg', otpDeliveryMsg1)
                  } 
                }
              } else if(resp.responseCode === "20") {
                if(state.currentStep === state.OTP_DELIVERED) {
                  commit('setCurrentStep', state.FLOW_COMPLETED)
                  // logger.info('Transaction completed')
                  dispatch('checkVerificationStatus')
                }
              } else if(resp.responseCode === "00") {
                if(state.flowType === 'forgot_password' && !pwd_less_new_login) {
                  if(state.currentStep === state.FLOW_INITIALIZED || state.currentStep === 'SQA_VALIDATED' || state.currentStep === "SQA_DISPLAYED") {
                    var devices = state.otpDevices.slice(1)
                    dispatch('formatDevices', devices)
                  } else if(state.currentStep === state.START_VERIFICATION) {
                    commit('setOtpSent', true)
                    commit('setCurrentStep', state.OTP_DELIVERED)
                    var alertMessageString = state.selectedOtpDevice.label;
                    var device=alertMessageString.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
                    otpDeliveryMsg1 = state.messages.en.message["otp_validate_directions"].replace("$0", device[1]);
                  }
                } else {
                if(state.currentStep === state.FLOW_INITIALIZED || state.currentStep === 'SQA_VALIDATED') {
                  //console.log("resp.data...",resp.data)
                  if(state.flowType == 'auth_sqa_flow') {
                    dispatch('updateFormatVcgSecretQuestion',resp.data.question)
                  } else {
                    var devices = resp.data.options
                    if(state.vbgFlow){
                      // Formatting the delivery options order like text, email, call for login & forgot_username flows                     
                      // This feature is already existed in forgot password flows.
                        if(state.currentStep === state.FLOW_INITIALIZED){
                        devices = response.data.data.listOfDevices;
                        dispatch('formatDevices', devices);
                       }
                       else dispatch('formatDevices', devices);
                    }
                    else if(!state.vbgFlow){
                      //console.log('@@@  response.data.data.listOfDevices ',  response.data.data.listOfDevices)
                      var tokenizedDevices = response.data.data.listOfDevices;
                      dispatch('formatDevices', tokenizedDevices);
                    }
                  }
                } else if(state.currentStep === state.START_VERIFICATION) {
                  commit('setOtpSent', true)
                  commit('setCurrentStep', state.OTP_DELIVERED)
                  // logger.info('OTP delivered to the user selected device')
                  var alertMessageString = state.selectedOtpDevice.label;
                  var device=alertMessageString.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
                  otpDeliveryMsg1 = state.messages.en.message["otp_validate_directions"].replace("$0", device[1]);
                  // commit('setOTPDeliveryMsg', otpDeliveryMsg)
                }
              }
              } else if(resp.responseCode === "05") {
                msg = [{msg: state.messages.en.message["reg_otp_verify_code_profile_not_found"], type:'error'}]
                errorSet(state.messages.en.message['reg_otp_verify_code_profile_not_found']);
                commit('setOTPAlert', msg)
              }
              // commit('setLoader', false)
              commit('setPollingLoader', false)
            }
            if(response.data.responseCode == '24') {
              // no otp devices for the user
              // logger.info('No registered OTP devices')
              if(state.flowType === 'forgot_password') {
                commit('setCannotProceedRecovery', true)
                // logger.warn('The user cannot proceed with password recovery')
                var msg = [{msg: state.messages.en.message["forgot_pd_cannot_proceed"], type: 'list'}]
                // dispatch('forgotflow/updateAlerts', msg, {root:true})
                errorSet(state.messages.en.message["forgot_pd_cannot_proceed"]);
                commit('setFpAlert', msg)
              } else if (state.flowType === 'forgot_username') {
                commit('setCannotRecoverUsername', true)
                var msg = [{msg: state.messages.en.message["forgot_username_cannot_proceed"], type: 'error'}]
                // dispatch('forgotflow/updateAlerts', msg, {root:true})
                errorSet(state.messages.en.message["forgot_username_cannot_proceed"]);
                commit('setFuAlert', msg)
              } else {
                var msg = {msg: state.messages.en.message["login_otp_no_devices"], type:'error'}
                errorSet(state.messages.en.message["login_otp_no_devices"]);
                var otpAlertArr = state.otpAlerts
                otpAlertArr.push(msg)
                commit('setOTPAlert', otpAlertArr)
                dispatch('formatDevices', [])
              }
            } else if(response.data.responseCode == '23') {
              if(state.flowType === 'forgot_password') {
                commit('setCannotProceedRecovery', true)
                // logger.warn('The user cannot proceed with password recovery')
                var msg = [{msg: state.messages.en.message["login_sqa_no_questions"], type: 'error'}]
                // dispatch('forgotflow/updateAlerts', msg, {root:true})
                errorSet(state.messages.en.message["login_sqa_no_questions"]);
                commit('setFpAlert', msg)
              }
            } else if(response.data.responseCode == '25') {
              if(state.flowType === 'forgot_password') {
               commit('setCannotProceedRecovery', true)
                // logger.warn('The user cannot proceed with password recovery')
                var msg = [{msg: state.messages.en.message["forgot_pd_cannot_proceed"], type: 'list'}]
                // dispatch('forgotflow/updateAlerts', msg, {root:true})
                errorSet(state.messages.en.message["forgot_pd_cannot_proceed"]);
                commit('setFpAlert', msg) 
              } else if(state.flowType === 'forgot_username') {
                commit('setCannotRecoverUsername', true)
                var msg = [{msg: state.messages.en.message["forgot_username_cannot_proceed"], type: 'error'}]
                // dispatch('forgotflow/updateAlerts', msg, {root:true})
                errorSet(state.messages.en.message["forgot_username_cannot_proceed"]);
                commit('setFuAlert', msg)
              } else {
                // auth flow
                var msg = {msg: state.messages.en.message["login_no_devices_no_questions"], type:'error'}
                errorSet(state.messages.en.message["login_no_devices_no_questions"]);
                var otpAlertArr = state.otpAlerts
                otpAlertArr.push(msg)
                commit('setOTPAlert', otpAlertArr)
              }
            } else if(response.data.responseCode === "00" && state.flowType === 'forgot_password' && !pwd_less_new_login) {
              if(state.vbgFlow){
                var devices = response.data.data.listOfDevices
                dispatch('formatDevices', devices)
              }
              else if(!state.vbgFlow){
                var tokenizedDevices = response.data.data.listOfDevices;
                  dispatch('formatDevices', tokenizedDevices);
              }
            } else if(response.data.responseCode === "00") {
              var devices = response.data.data.listOfDevices
              commit('setTransactionDeliveryMethods', devices)
            }
            if (!state.vbgFlow) {
              //console.log("@@@ initializeStepup:: VCG createVZIGInstance ");
              //var igBackendUrl = iamvar_newServerUrl + '/accessmanager/public/adaptiveauth/2fa/polling'
              var igBackendUrl =  iamvar_vcgCvsApi
              //var igBackendUrl = iamvar_adaptiveauth_polling_url;
              //console.log("@@@ initializeStepup 100 ");
              commit("createVZIGInstance", {
                transactionID,
                connType,
                igBackendUrl,
                callBackFunction,
              });
            } else {
              //console.log("@@@ initializeStepup 100 ");
            commit("createVZIGInstance", {
              transactionID,
              connType,
              igBackendUrl,
              callBackFunction,
              });
            }


            
 
            commit('setCurrentStep',state.VZIGINSTANCE_CREATED)
            if(state.flowType=== 'forgot_username') {

              // skip this message if otp device was previously blocked
              if(!state.otpDeviceBlockEvent && !state.selectedOtpDevice.deviceType) {
                var msg = [ {	msg : state.messages.en.message['forgot_username_user_details_verified'],type : 'success'} ]
                commit('setOTPAlert', msg)
              }
              
              eventTracking('Username Recovery Success',response.data)
            }
            dispatch('updateStepupReason', response.data.stepupReason)
            commit('setFlowInitialized', response.data.status)
            commit('setCurrentStep',state.FLOW_INITIALIZED)


          }
          commit('setLoader', false)
        }
          }).catch((error) => {
            //console.log('entered catch()')
            //404 if the user doesnt exist
            commit('setLoader', false)
            var errorData = error.response
            if (state.flowType === 'forgot_username') {
              if (errorData.headers['x-vz-error-msg'] === "MAX_ATTEMPTS_REACHED") {
                var errorMsg =  state.messages.en.message["forgot_username_max_attempts_reached"]
                errorSet(state.messages.en.message["forgot_username_max_attempts_reached"]);
                dispatch('login/updateUnifiedLoginAlerts', [{msg: errorMsg, type: 'newError'}], { root: true })
                dispatch('login/setAuthErrorUpdate', { errorMessage: errorMsg }, {root:true})
                dispatch('login/setAlertTypeUpdate', 'error', {root:true})
                commit('setCvsAuthAlert', { errorMessage: errorMsg })
                var returnUrl = getHostName(window.location.href);
                if (!state.urlOnMount) {
                  router.push({ path: '/login/unifiedlogin' })
                  //window.location.href = "https://" + returnUrl + "/account/business/login/unifiedlogin";
                } else {
                  const urlStr = '/account/business'
                  if (state.urlOnMount.includes(`${urlStr}/login/unifiedlogin`)) {
                    const gotoURL = getParameterByNameURL('goto', state.urlOnMount);
                      if(gotoURL) {
                        router.push({ path: '/login/unifiedlogin', query: {goto: gotoURL}})
                      } else {
                        router.push({ path: '/login/unifiedlogin' })
                      }
                  } else if (state.urlOnMount.includes(`${urlStr}/login/mobile`)){
                    const gotoURL = getParameterByNameURL('goto', state.urlOnMount);
                      if(gotoURL) {
                        router.push({ name: 'MobileLoginComponent', query: {goto: gotoURL}})
                      } else {
                        router.push({ name: 'MobileLoginComponent'})
                      }
                  } else if (state.urlOnMount.includes(`${urlStr}/ilogin`)){
                    const gotoURL = getParameterByNameURL('goto', state.urlOnMount);
                      if(gotoURL) {
                        router.push({ name: 'iLoginComponent', query: {goto: gotoURL}})
                      } else {
                        router.push({ name: 'iLoginComponent'})
                      }
                  } else if (state.urlOnMount.includes(`${urlStr}/login`)) {
                    const gotoURL = getParameterByNameURL('goto', state.urlOnMount);
                      if(gotoURL) {
                        router.push({ name: 'SimpleLoginRoot', query: {goto: gotoURL}})
                      } else {
                        router.push({ name: 'SimpleLoginRoot'})
                      }
                    // router.push({ name: 'SimpleLoginRoot'})
                  } else {
                    router.push({ path: '/login/unifiedlogin' })
                  }
                }
              }
            }


            if(errorData.status == 417) {
              // document.write(errorData.data);
              if(docHTML) {
                docHTML[0].innerHTML = errorData.data;
              }
            }

            if(errorData.status === 501) {
              //console.log('Response message ' + errorData.data.responseMessage)
              var msg = [ {	msg : state.messages.en.message[errorData.data.responseMessage],type : 'error'} ]
              errorSet(state.messages.en.message[errorData.data.responseMessage]);
              commit('setOTPAlert', msg)
              commit('setSQAAlert', msg)
              // logger.warn(msg)
            } else if(errorData.status === 404) {
              // logger.warn('No user found')
              if(errorData.data === 'NO_USER') {
                if(state.flowType === 'forgot_username' && state.userIdFlow == 'BASIC') {
                  var msg = [ {	msg : state.messages.en.message['forgot_username_no_user_found'],type : 'list'} ]
                  errorSet(state.messages.en.message['forgot_username_no_user_found']);
                  commit('setFuAlert', msg)
                  commit('setClearInputFields', true);
                } else if(state.flowType === 'forgot_username' && (state.userIdFlow == 'COMPLEX' || state.userIdFlow == 'SIMPLE')) {
                  var msg = [ {	msg : state.messages.en.message['forgotUserId_complex_error'],type : 'error'} ]
                  errorSet(state.messages.en.message['forgotUserId_complex_error']);
                  commit('setFuAlert', msg)
                  commit('setClearInputFields', true)
                } else if(state.flowType === 'forgot_password') {
                  commit('setClearInputFields', true);
                  // errorSet(state.messages.en.message['forgot_username_no_user_found']);
                  // commit('setUserValidationError', true)
                  var msg = [ {	msg : state.messages.en.message['forgot_pd_no_user_found'],type : 'error'} ]
                  errorSet(state.messages.en.message['forgot_pd_no_user_found']);
                  commit('setFpAlert', msg)
                }
              } else if(errorData.data === 'MULTI_USER') {
                  if(state.flowType === 'forgot_username') {
                    var msg = [ {	msg : state.messages.en.message['forgot_username_multi_user_found'],type : 'list'} ]
                    errorSet(state.messages.en.message['forgot_username_multi_user_found']);
                    commit('setFuAlert', msg)
                  } else if(state.flowType === 'forgot_password') {
                    var msg = [ {	msg : state.messages.en.message['forgot_pd_multi_user_found'],type : 'list'} ]
                    errorSet(state.messages.en.message['forgot_pd_multi_user_found']);
                    commit('setFpAlert', msg)
                  }
                } else {
                  // generic network error
                  commit('setOTPAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
                  commit('setSQAAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
                }
            } else if(errorData.status === 409) {
                if(state.flowType === 'forgot_username') {
                  if(errorData.data === 'INVALID_CAPTCHA') {
                    var msg1 = [ {	msg : state.messages.en.message['forgot_flows_jcaptcha_invalid'],type : 'error'} ]
                    errorSet(state.messages.en.message['forgot_flows_jcaptcha_invalid']);
                    commit('setFuAlert', msg1)
                  }
                  if(state.userIdFlow == 'SIMPLE' || state.userIdFlow == 'COMPLEX') {
                    //router.push({ path: '/login/unifiedlogin' })
                    var returnUrl = getHostName(window.location.href);
                    if (!state.urlOnMount) {
                      window.location.href = mixin.methods.sanitizeUrl("https://" + returnUrl + "/account/business/login/unifiedlogin");
                    } else {
                      window.location.href = mixin.methods.sanitizeUrl(state.urlOnMount);
                    }
                  }
                } else if(state.flowType === 'forgot_password') {
                  if(errorData.data === 'INVALID_CAPTCHA') {
                    var msg5 = [ {	msg : state.messages.en.message['forgot_flows_jcaptcha_invalid'],type : 'error'} ]
                    errorSet(state.messages.en.message['forgot_flows_jcaptcha_invalid']);
                    commit('setFpAlert', msg5)
                  }
                }
            } else if(errorData.status === 401) {
              // user account locked
              if(state.flowType=== 'forgot_username') {
                // For VBM User in forgot username we have to redirect to iamvar_vbm_fu_redirect
                if(errorData.data == "VBM_USER") {
                  window.location.href = iamvar_vbm_fu_redirect;
                } else if(errorData.data == "SENT_WELCOME_EMAIL") { 
                  let errorMsg = [{msg: state.messages.en.message["forgot_flow_sent_welcome_email"], type: 'information'}]
                  errorSet(state.messages.en.message["forgot_flow_sent_welcome_email"]);
                  commit('setFuAlert', errorMsg)
                } else if(errorData.data == "CONTACT_ADMIN") { 
                  let errorMsg = [{msg: state.messages.en.message["forgot_username_cannot_proceed"], type: 'error'}]
                  errorSet(state.messages.en.message["forgot_username_cannot_proceed"]);
                  commit('setFuAlert', errorMsg)
                } else if(errorData.data == "LOCKED_USER") {
                  let errorMsg = [{msg: state.messages.en.message["forgot_username_locked_user"], type: 'error'}]
                  errorSet(state.messages.en.message["forgot_username_locked_user"]);
                  commit('setFuAlert', errorMsg)
                } else if (errorData.data == "EMPTY_DEVICE_LIST") {
                  errorSet(state.messages.en.message["forgot_pd_empty_device_list"]);
                  commit('setFuAlert', [{msg: state.messages.en.message["forgot_pd_empty_device_list"], type: 'error'}])
                } else if(errorData.data == "GENERIC_ERROR") {
                  let errorMsg = [{ msg: state.messages.en.message['bckend_unknown_err'], type: 'error' }] 
                  commit('setFuAlert', errorMsg)
                } else if(errorData.data == "INVALID_TOKEN") {
                  var returnUrl = getHostName(window.location.href);
                  if (!state.urlOnMount) {
                    window.location.href = mixin.methods.sanitizeUrl("https://" + returnUrl + "/account/business/login/unifiedlogin");
                  } else {
                    window.location.href = mixin.methods.sanitizeUrl(state.urlOnMount);
                  }
                } else if (errorData.data == "PROSPECT_ENROLLED_PENDING_REGISTRATION") {
                  errorSet(state.messages.en.message["prospect_registration_pending"]);
                  commit('setFuAlert', [{ msg: state.messages.en.message["prospect_registration_pending"], type: 'error' }])
                } else if (errorData.data == "B360_ENROLLED_PENDING_REGISTRATION") {
                  errorSet(state.messages.en.message["am_error_B360_enrolled"]);
                  commit('setFuAlert', [{msg: state.messages.en.message["am_error_B360_enrolled"], type: 'error'}])
                } else if (errorData.data == "LOCKED_ECPD_ADMIN_USER"){
                  errorSet(state.messages.en.message["forgot_flows_locked_ecpd_admin_user"]);
                  commit('setFuAlert', [{ msg: state.messages.en.message["forgot_flows_locked_ecpd_admin_user"], type: 'error' }])
                } else if (errorData.data == "LOCKED_ECPD_NON_ADMIN_USER"){
                  errorSet(state.messages.en.message["forgot_flows_locked_ecpd_non_admin_user"]);
                  commit('setFuAlert', [{ msg: state.messages.en.message["forgot_flows_locked_ecpd_non_admin_user"], type: 'error' }])
                } else if (errorData.data == "INVALID_DOMAIN_INACTIVE_USER"){
                  errorSet(state.messages.en.message["poc_dormant_user_blocked"]);
                  commit('setFuAlert', [{ msg: state.messages.en.message["poc_dormant_user_blocked"], type: 'error' }])
                } else if ( iamvar_federalErrorMessages && errorData.data == "FED_PAT_MIGRATED_NOT_ENABLED") {
                  const searchParams = new URLSearchParams(window.location.search);
                  let goto = searchParams.get('goto')
                  if(goto == null){
                  errorSet(state.messages.en.message["am_error_fed_portal_gen_msg"]);
                  commit('setFuAlert', [{msg: state.messages.en.message["am_error_fed_portal_gen_msg"], type: 'error'}])
                  }else{
                  const searchParams = new URLSearchParams(window.location.search);
                  let goto = searchParams.get('goto')
                  let nxMessage = iamvar_nx_redirect_url.slice('8')
                  let neMessage = iamvar_ne_redirect_url.slice('8')
                  let witsMessage = iamvar_wi_redirect_url.slice('8')
                  let saoMessage = iamvar_sa_redirect_url.slice('8')
                  if(goto.includes(iamvar_nx_domain)){
                    errorSet(state.messages.en.message["am_error_fed_portal_msg"]);
                    commit('setFuAlert', [{msg: `${state.messages.en.message["am_error_fed_portal_msg"]} 
                    <a href="${iamvar_nx_redirect_url}" style='color:#000000; font-weight:bold;'  >${nxMessage}</a>`, type: 'error'}])
                }
                  if(goto.includes(iamvar_ne_domain)){
                  errorSet(state.messages.en.message["am_error_fed_portal_msg"]);
                  commit('setFuAlert', [{msg: `${state.messages.en.message["am_error_fed_portal_msg"]} 
                  <a href="${iamvar_ne_redirect_url}" style='color:#000000; font-weight:bold;'  >${neMessage}</a>`, type: 'error'}])
                }
                  if(goto.includes(iamvar_wi_domain)){
                  errorSet(state.messages.en.message["am_error_fed_portal_msg"]);
                  commit('setFuAlert', [{msg: `${state.messages.en.message["am_error_fed_portal_msg"]} 
                  <a href="${iamvar_wi_redirect_url}" style='color:#000000; font-weight:bold;'  >${witsMessage}</a>`, type: 'error'}])
                 }
                 if(goto.includes(iamvar_sa_domain)){
                errorSet(state.messages.en.message["am_error_fed_portal_msg"]);
                commit('setFuAlert', [{msg: `${state.messages.en.message["am_error_fed_portal_msg"]} 
                <a href="${iamvar_sa_redirect_url}" style='color:#000000; font-weight:bold;'  >${saoMessage}</a>`, type: 'error'}])
          }
}
                }else {
                  var msg2 = [ {	msg : state.messages.en.message['forgot_username_user_account_locked'],type : 'error'} ]
                  errorSet(state.messages.en.message['forgot_username_user_account_locked']);
                  commit('setFuAlert', msg2)
                }
              } else if(state.flowType === 'forgot_password') {
                if(iamvar_polarisFlowToggle && errorData.data == 'SDAR_PENDING_REGISTRATION'){
                  router.push({ path: '/login/recovery/forgotpassworderrorpage' });
                }
                // For VBM User in forgot password we have to redirect to iamvar_vbm_fu_redirect
                if(errorData.data == "VBM_USER") {
                  window.location.href = iamvar_vbm_fp_redirect;
                } else if(errorData.data == "CONTACT_ADMIN") { 
                  var msg7 = [ {	msg : state.messages.en.message['forgot_pd_contact_admin'],type : 'error'} ]
                  errorSet(state.messages.en.message['forgot_pd_contact_admin']);
                  commit('setFpAlert', msg7)
                  commit('setUserValidationContactAdminError', false)
                } else if (errorData.data == "PROSPECT_ENROLLED_PENDING_REGISTRATION") {
                  errorSet(state.messages.en.message["prospect_registration_pending"]);
                  commit('setFpAlert', [{ msg: state.messages.en.message["prospect_registration_pending"], type: 'error' }])
                } else if (errorData.data == "B360_ENROLLED_PENDING_REGISTRATION") {
                  errorSet(state.messages.en.message["am_error_B360_enrolled"]);
                  commit('setFpAlert', [{msg: state.messages.en.message["am_error_B360_enrolled"], type: 'error'}])
                } else if (errorData.data == "EMPTY_DEVICE_LIST") {
                  errorSet(state.messages.en.message["forgot_pd_empty_device_list"]);
                  commit('setFpAlert', [{msg: state.messages.en.message["forgot_pd_empty_device_list"], type: 'error'}])
                } else if ( iamvar_federalErrorMessages && errorData.data == "FED_PAT_MIGRATED_NOT_ENABLED") {
                  const searchParams = new URLSearchParams(window.location.search);
                  let goto = searchParams.get('goto')
                  if(goto == null){
                  errorSet(state.messages.en.message["am_error_fed_portal_gen_msg"]);
                  commit('setFpAlert', [{msg: state.messages.en.message["am_error_fed_portal_gen_msg"], type: 'error'}])
                  }else{
                    const searchParams = new URLSearchParams(window.location.search);
                    let goto = searchParams.get('goto')
                    let nxMessage = iamvar_nx_redirect_url.slice('8')
                    let neMessage = iamvar_ne_redirect_url.slice('8')
                    let witsMessage = iamvar_wi_redirect_url.slice('8')
                    let saoMessage = iamvar_sa_redirect_url.slice('8')
                    if(goto.includes(iamvar_nx_domain)){
                      errorSet(state.messages.en.message["am_error_fed_portal_msg"]);
                      commit('setFpAlert', [{msg: `${state.messages.en.message["am_error_fed_portal_msg"]} 
                      <a href="${iamvar_nx_redirect_url}" style='color:#000000; font-weight:bold;'  >${nxMessage}</a>`, type: 'error'}])
                  }
                    if(goto.includes(iamvar_ne_domain)){
                    errorSet(state.messages.en.message["am_error_fed_portal_msg"]);
                    commit('setFpAlert', [{msg: `${state.messages.en.message["am_error_fed_portal_msg"]} 
                    <a href="${iamvar_ne_redirect_url}" style='color:#000000; font-weight:bold;'  >${neMessage}</a>`, type: 'error'}])
                  }
                    if(goto.includes(iamvar_wi_domain)){
                    errorSet(state.messages.en.message["am_error_fed_portal_msg"]);
                    commit('setFpAlert', [{msg: `${state.messages.en.message["am_error_fed_portal_msg"]} 
                    <a href="${iamvar_wi_redirect_url}" style='color:#000000; font-weight:bold;'  >${witsMessage}</a>`, type: 'error'}])
                   }
                   if(goto.includes(iamvar_sa_domain)){
                   errorSet(state.messages.en.message["am_error_fed_portal_msg"]);
                   commit('setFpAlert', [{msg: `${state.messages.en.message["am_error_fed_portal_msg"]} 
                   <a href="${iamvar_sa_redirect_url}" style='color:#000000; font-weight:bold;'  >${saoMessage}</a>`, type: 'error'}])
            }
}
                }else {
                  var msg = '';

                  if(errorData.data == "FORCED_PWD_RESET") { // regStatus = KBR
                    msg = [ { msg: state.messages.en.message['forgot_pd_forced_pwd_reset'], type: 'list' } ]
                    errorSet(state.messages.en.message['forgot_pd_forced_pwd_reset'])
                  } else if(errorData.data == "INCOMPLETE_SEC_PROFILE") { // regStatus = KBTC
                    
                    msg = [ { msg: state.messages.en.message['forgot_pd_cannot_proceed'], type: 'list' } ]
                    errorSet(state.messages.en.message['forgot_pd_cannot_proceed'])
                  } else if(errorData.data == "SENT_WELCOME_EMAIL") { 
                    msg = [{msg: state.messages.en.message["forgot_flow_sent_welcome_email"], type: 'information'}]
                    errorSet(state.messages.en.message["forgot_flow_sent_welcome_email"]);
                  } else if(errorData.data == "LOCKED_USER") {
                    msg = [{msg: state.messages.en.message["forgot_pd_locked_user"], type: 'list'}]
                    errorSet(state.messages.en.message["forgot_pd_locked_user"]);
                  } else if(errorData.data == "GENERIC_ERROR") {
                    msg = [{ msg: state.messages.en.message['bckend_unknown_err'], type: 'error' }]  //should ask anuj for state.flowType === 'forgot_password'
                  } else if (errorData.data == "LOCKED_ECPD_ADMIN_USER"){
                    msg = [ {	msg : state.messages.en.message['forgot_flows_locked_ecpd_admin_user'],type : 'list'} ]
                    errorSet(state.messages.en.message['forgot_flows_locked_ecpd_admin_user']);
                  } else if (errorData.data == "LOCKED_ECPD_NON_ADMIN_USER"){
                    msg = [ {	msg : state.messages.en.message['forgot_flows_locked_ecpd_non_admin_user'],type : 'list'} ]
                    errorSet(state.messages.en.message['forgot_flows_locked_ecpd_non_admin_user']);
                  } else if (errorData.data == "INVALID_DOMAIN_INACTIVE_USER"){
                    msg = [ {	msg : state.messages.en.message['poc_dormant_user_blocked'],type : 'list'} ]
                    errorSet(state.messages.en.message['poc_dormant_user_blocked']);
                  }
                  else {
                    msg = [ {	msg : state.messages.en.message['forgot_pd_user_account_locked'],type : 'list'} ]
                    errorSet(state.messages.en.message['forgot_pd_user_account_locked']);
                  }
                  commit('setFpAlert', msg)
                
                }
              } else {
                // generic network error

                if (errorData.data == "EMPTY_DEVICE_LIST") {
                  errorSet(state.messages.en.message["forgot_pd_empty_device_list"])
                  commit('setOTPAlert', [{msg: state.messages.en.message["forgot_pd_empty_device_list"], type: 'error'}])
                  dispatch('login/updateEmptyUnifiedLoginAlerts', [{msg: state.messages.en.message["forgot_pd_empty_device_list"], type: 'newError'}], { root: true })
                  dispatch('login/updateCVSSubmitLoader', false, {root:true})
                  setTimeout(function () {
                    if(document.getElementById("IAMerrorMessage")) {
                      document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                      document.getElementById("IAMerrorMessage").innerHTML = state.messages.en.message["forgot_pd_empty_device_list"]
                    }
                  }, 1000)
                  if(state.flowType == "login_assistant" && iamvar_loginAssistant){
                    router.push({ name: 'PwdLessPassword'});
                  }
                } else {
                commit('setOTPAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
                commit('setSQAAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
                }
              }
            } else if(errorData.status === 400) {
              // user is federated
              var msg = [ { msg: state.messages.en.message['akka_error_federated_restriction'], type: 'error' } ]
              errorSet(state.messages.en.message['akka_error_federated_restriction']);
              if(state.flowType === 'forgot_password') {
                commit('setFpAlert', msg)
              } else if(state.flowType === 'forgot_username') {
                commit('setFuAlert', msg)
              } else {
                // generic network error
                commit('setOTPAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
                commit('setSQAAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
              }
            } else {
              // generic network error
              var errorMsg =  state.messages.en.message["am_error_internal_err"]
              errorSet(state.messages.en.message["am_error_internal_err"]);
              dispatch('login/updateUnifiedLoginAlerts', [{msg: errorMsg, type: 'newError'}], { root: true })
              commit('setOTPAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
              commit('setSQAAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
              commit('setFuAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
              commit('setFpAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
              commit('enableFFSQAoption', false)
              // log unhandled error
              let requestObject = { reqUrl: restEndpoint, reqBody: state.sanitizedPayload, reqHeaders: state.options }
              let explanation = "This call gets a transaction ID from Akka"

              let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

              dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
            }
            // logger.error('Error in initialization '+error)
            throw error
          })
      },
      wirelessObj({state, commit}, payload) {
        commit('setWirelessObject', payload)
      },
      mobilenumberValidate({state, commit}, payload) {
        commit('setIsMobilenumberValid', payload)
      },
      showWirelessNumber({state, commit}, payload) {
        commit('setIsShowWirelessNumber', payload)
      },
      sendMDN({state, commit}, payload) {
        commit('setSendMDN', payload)
      },
      formatDevices({state, commit ,dispatch}, payload) {
        var devices = []
        if(!pwd_less_new_login) devices[0] = state.defaultSelectedDevice;
        commit('setOtpSent', true)
        if(!state.vbgFlow){
          //console.log("@@@ formatDevices from get Trancation")
          for(var i = 0; i < payload.length; i++) {
            var deviceEnable = payload[i].deviceEnable
            if(deviceEnable == true) {
              var deviceType = payload[i].deviceType
              var deviceValue = payload[i].deviceValue
              payload[i].label = state.messages.en.message["vcg_cvs_otp_via_"+deviceType].replace("$0", deviceValue)
              devices.push(payload[i])
              //console.log("@@@ devices ",devices)
            }
          }
          //console.log("@@@ formatDevices from get Trancation devices ",devices)
        }else{
          if( (state.flowType === 'forgot_password' && !pwd_less_new_login)  || state.currentStep === state.FLOW_INITIALIZED ) {
            for(var i = 0; i < payload.length; i++) {
              var deviceType = payload[i].deviceType
              var deviceValue = payload[i].deviceValue
              payload[i].label = state.messages.en.message["vcg_cvs_otp_via_"+deviceType].replace("$0", deviceValue)
              devices.push(payload[i])
            }
          } else {
        for(var i = 0; i < payload.length; i++) {
          var deviceType = payload[i].deviceType
          var label = payload[i].label
          var labelSplitArr = label.split(" ")
          var deviceValue = ''
          if(labelSplitArr.length>0) {
            deviceValue = labelSplitArr[labelSplitArr.length-1]
          } else {
            deviceValue = label
          }
          payload[i].label = state.messages.en.message["cvs_otp_via_"+deviceType].replace("$0", deviceValue);
          if(state.flowInitialized === "SQA_INITIALIZED") {
            if(payload[i].deviceType === "SQA") {
              devices.push(payload[i])
            }
          } else if(state.flowInitialized === "OTP_INITIALIZED" || state.flowInitialized === "ALLOW_INITIALIZED") {
            if(payload[i].deviceType !== "SQA") {
              devices.push(payload[i])
            }
          }
          }
        }
        }
        if(devices.length == 0) {
          // reset existing otp alerts
          commit('setOTPAlert', [ ]) 
          commit('setWirelessAlerts', [])
          var msg = {msg: state.messages.en.message["login_otp_no_devices"], type:'error'}
          errorSet(state.messages.en.message['login_otp_no_devices']);
          var otpAlertArr = state.otpAlerts
          otpAlertArr.push(msg)
          commit('setOTPAlert', otpAlertArr)
        }
        if(state.wirelessObject && state.wirelessObject.deviceValue == '***-***-LDER') {
          //var devicesCopy = [];
          /*for(var j = 0;j<devices.length;j++) {
            if(devices[j].deviceValue != state.wirelessObject.deviceValue) {
              devicesCopy.push(devices[j]);
            } else {
              devicesCopy.push(state.wirelessObject);
            }
          }*/
          //devices = devicesCopy;
          devices.push(state.wirelessObject);
        }
        if(state.showMtnDeliveryMethod){
          devices = devices.filter(
            (device) =>
              device.deviceType == "SMARTPHONE" || device.deviceType == "BASIC"
          );
        }
        //console.log("devices are..",devices)
        commit('setOtpDevices', devices)
        commit('setCurrentStep',state.OTP_OPTIONS_DISPLAYED)
        if( !state.showMtnDeliveryMethod ) {
          dispatch('setSelectedOtpDevice')
        } else {
          commit('setShowMtnDeliveryMethod', false)
          dispatch('setSelectedOtpDevice', state.otpDevices[state.selectedDeviceIndex])
          dispatch('sendOtpToDevice', state.otpDevices[state.selectedDeviceIndex].deviceID)
        }
      },
      setPageloader({state, commit}, payload) {
        commit('setLoader', payload)
      },
      getOtpDevices ({state, commit, dispatch}) {
        commit('setInitialRouteToken', window.localStorage.getItem('InitialRoute'))
        try {
          // if(state.flowType === 'forgot_password') {
            // commit('setLoader', true)
          // }
          commit('setHideOtpValidateComponent', true)
          commit('setVerificationMode', 'otp')
          if(state.flowType == 'forgot_username'){
            dispatch('pollingEvent', {requestType: '2FA_DEVICE_OPTIONS'})
            } else {
          state.vzigInstance.getDeviceOptions()
            }
          commit('setPollingLoader', true)
          //console.log("On successfull - OTP devices allocation")
        } 
        catch (err) {
          commit('setLoader', false)
          if(!state.otpDevices.length) {
            if(state.initialRouteToken) {
              //console.log('cvstepup - catch - Initialroute', state.initialRouteToken)
              window.location.href = state.initialRouteToken
            } else {
              // Default redirecting to Unified Login
              router.push({ name: 'PwdLessLoginRootComponent'})
            }
          }
        }
      },
      getUserQuestions ({commit, state}) {
        commit('setVerificationMode', 'sqa')
        commit('setLoader', true)
        commit('setOTPAlert', [])
        commit('setSQAAlert', [])
        commit('setOtpSent', false)
        var payload = {
          'requestType': 'GET_SQA',
          'transactionID': state.transactionId,
          'vbgFlow': state.vbgFlow
        }
        // var payload = {
        //   'requestType': '2FA_OTHER_OPTIONS',
        //   'transactionID': state.transactionId
        // }
        return playrest.post(iamvar_recoveryServ+'/get-sqa', payload).then(function (response) {
          var userQ = {}
          if(response.data.responseCode == '00') {
            userQ.question = response.data.data.question
            userQ.answer = null
            commit('setUserQuestions', userQ)
            commit('setSelectedQuestion')
            commit('setCurrentStep', state.SQA_DISPLAYED)
            commit('setSQAValidated', false)
          } else if(response.data.responseCode == '23') {
            commit('setDisableSqaFields', true)    
            var msg = [{msg: state.messages.en.message["login_sqa_no_questions"], type:'error'}]
            errorSet(state.messages.en.message['login_sqa_no_questions']);
            commit('setSQAAlert', msg)
            commit('setOTPAlert', msg)
          }
          commit('setLoader', false)
        }).catch((error) => {
          // generic network error
          var errorData = error.response
          commit('setSQAAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
          commit('setOTPAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
          commit('enableFFSQAoption', false)
          commit('setLoader', false)
          if(errorData.status === 500) {
            router.push({ name: 'AMForgotPwdComponent'})
          }
          // log unhandled error
          let requestObject = { reqUrl: restEndpoint, reqBody: payload, reqHeaders: state.options }
          let explanation = "This call should return the SQA for the user"

          let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

          dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
          throw error
        })
      },
      updateStepupReason({commit, state}, payload) {
        commit('setStepupReason', payload)
        if(payload === 'DEVICE_NOT_REGISTERED') {
          commit('enableSQAOption', true)
          commit('enableOTPOption', true)
        } else if(payload === 'RISK') {
          commit('enableSQAOption', true)
          commit('enableOTPOption', true)
          if(state.flowInitialized === 'SQA_INITIALIZED') {
            commit('enableOTPOption', false)
          } else if(state.flowInitialized === 'OTP_INITIALIZED') {
            commit('enableSQAOption', false)
          }
        } else if(payload === 'SQA_NOT_REGISTERED') {
          commit('enableSQAOption', false)
          /* var msg = [{msg: state.messages.en.message["sqa_disabled_for_security"], type:'information'}]
          errorSet(state.messages.en.message['sqa_disabled_for_security']);
          commit('setOTPAlert', msg) */
          commit('setNoSqaReg', true)
        }
        var stepupReasons = stepUpConfig.stepupReasons
        var reason = stepupReasons.filter(x => x.reason === payload)[0]
        if (reason) {
          //console.log('setting up sqa messages, reason=', reason)
          commit('setStepupPageMessages', reason)
        } else {
          // if reason is null
        }
      },
      async updateSelectedDevice({commit, dispatch, state}, payload) {
        if(state.currentStep !== state.OTP_OPTIONS_DISPLAYED) {
          if(payload.deviceID !== 'select-label') {
            await dispatch('cancelVerification')
            await dispatch('initializeStepup')
            await dispatch('getOtpDevices')
            dispatch('setOldSelectedDevice', payload)
          } else {
            dispatch('setSelectedOtpDevice', payload)
            commit('setOtpSent', true)
            commit('setHideOtpValidateComponent', true)
          }
        } else {
          //dispatch('setSelectedOtpDevice', payload)
          //commit('setOtpSent', false)
          if(payload.deviceID !== 'select-label') {
            dispatch('setSelectedOtpDevice', payload)
            commit('setOtpSent', false)
          } else {
            dispatch('setSelectedOtpDevice', payload)
            commit('setOtpSent', true)
            commit('setHideOtpValidateComponent', true)
          }
        }
        if( state.flowType === 'forgot_password' && state.dnrStatus) {
          if( payload.deviceType == "select" || ( payload.deviceType == 'SMARTPHONE' || payload.deviceType == 'BASIC' )) {
            commit('enableFFSQAoption', false)
          } else {
            commit('enableFFSQAoption', true)
          }
        }
        if(state.flowType == 'forgot_password' && payload.vzCarrier ) {
          dispatch('forgotflow/updateVZCarrier', payload.vzCarrier, {root:true})
        }

        // }
      },
      setOldSelectedDevice({commit}, payload) {
        commit('setOldDevice', payload)
      },
      setResendSelectedDevice({commit}, payload) {
        commit('setResendDevice', payload)
      },
      sendOtpToDevice({commit, state, dispatch,rootState}, payload) {
        commit('setOTPAlert', [])
        commit('setWirelessAlerts', [])
        if(state.flowType === 'forgot_password') {
          // commit('setLoader', true)
          commit('setVerificationMode', "otp")
        }
        if(state.wirelessObject && state.wirelessObject.deviceValue == '***-***-LDER' && state.selectedOtpDevice.deviceID == state.wirelessObject.deviceID) {
          var msg;
          let restEndpoint = iamvar_iamStepupServ + '/request-stepup/send-otp-to-other-device'
          let restClient = playrest
          var optionalChallenge = null
          var requestSettings = { headers: { } }
          var requestPayload = {};
          if(state.flowType === 'forgot_password') {
            requestPayload = {
              "requestType": "2FA_INITIALIZE_PHONE",
              "MDN": state.wirelessMob,
              "transactionID": state.transactionId,
              "previousTransactionID": state.forgotPwdSqaTransactionId, 
              "challengeMethod" : "OTP"
            }
          } else if(state.flowType === 'POST_AUTH_FLOW') {
            //console.log('flowtype = post auth')
            restClient = iamPostAuthPlayrest
            // assign default challengeMethod (when user tries to resend passcode, challengeMethod would be null)
            if(optionalChallenge == null) {
              optionalChallenge = { challengeMethod: 'OTP' }
            }
            //console.log('from initializeStepup challenge method: ', optionalChallenge.challengeMethod)
            requestPayload.requestType = 'POST_AUTH_FLOW'
            dispatch('getSessionCookie')
            
            requestPayload.challengeMethod = optionalChallenge.challengeMethod
            //console.log('payload from post auth = ', requestPayload)
            requestPayload.MDN = state.wirelessMob
            requestPayload.transactionID = state.transactionId
            
            // set header settings
            // requestSettings.headers = { 'sessionCookie': state.sessionCookie }
            //console.log('header settings obj', requestSettings)
          } else {
            requestPayload = {
              "requestType": "2FA_INITIALIZE_PHONE",
              "MDN": state.wirelessMob,
              "transactionID": state.transactionId
            }
          }
          if(state.isMobilenumberValid) {
            return restClient.post(restEndpoint, requestPayload, requestSettings).then(function (response){
              var alertMessageString = state.selectedOtpDevice.label;
              var device=alertMessageString.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
              var otpDeliveryMsg1 = state.messages.en.message["otp_validate_directions"].replace("$0", device[1]);
              let deviceTypeLabel = state.selectedOtpDevice.label.split(' ')
              if (iamvar_pwdLessFlowType == '') {
              let otpSentMsg = state.messages.en.message["login_otp_"+state.selectedOtpDevice.deviceType+"_code_sent"].replace("$0", deviceTypeLabel[deviceTypeLabel.length - 1])
              commit('setOTPAlert', [ { msg: otpSentMsg, type: 'information' } ])
              }
              commit('setOTPDeliveryMsg', otpDeliveryMsg1)
              commit('setTransactionId', response.data.transactionID)
              var transactionID = state.transactionId
              //console.log('transaction ID ', transactionID)
              //console.log("response.data.data..",response.data.data)
              var connType = 'POLLING'
              //console.log('new server url ', iamvar_newServerUrl)
              var igBackendUrl = iamvar_newServerUrl + iamvar_cvsApiRoute + iamvar_cvsStepupServ+'/2fa/polling'
              var otpDeliveryMsg1 = null;
              var callBackFunction = function(resp) {
                //console.log(resp.responseMessage)
                //console.log(resp.responseCode)
                if(resp.responseCode === "02") {
                  //console.log('Processing Error')
                  // logger.error('Error in processing request')
                } else if(resp.responseCode === "03") {
                  commit('setCurrentStep', state.OTP_FAILED_TO_DELIVER)
                  commit('setOtpSent', false)
                  var msg = [{msg: state.messages.en.message["login_otp_send_error"], type:'danger'}];
                  errorSet(state.messages.en.message["login_otp_send_error"]);
                  commit('setOTPAlert', msg)
                } else if(resp.responseCode === "06") {
                  // max attempts
                } else if(resp.responseCode === "07") {
                  // token expired
                  commit('setCurrentStep', state.TOKEN_EXPIRED)
                  // logger.warn('Transaction token expired')

                } else if(resp.responseCode === "09") {
                  // invalid request
                } else if(resp.responseCode === "10") {

                } else if(resp.responseCode === "11" || resp.responseCode === "12" || resp.responseCode === "13") {
                  commit('setOtpSent', true)
                  commit('setCurrentStep', state.OTP_DELIVERED)
                  //dispatch('updateResendVerificationToSelectedDevice', false)
                  // logger.info('OTP delivered to the user selected device')
                  // state.seelctedOtpDevice.label format = EMAIL   e***@e***.com, 3 spaces in between
                  let deviceTypeLabel = state.selectedOtpDevice.label.split(' ')
                  if (iamvar_pwdLessFlowType == '') {
                  let otpSentMsg = state.messages.en.message["login_otp_"+state.selectedOtpDevice.deviceType+"_code_sent"].replace("$0", deviceTypeLabel[deviceTypeLabel.length - 1])
                  commit('setOTPAlert', [ { msg: otpSentMsg, type: 'information' } ])
                  }
                  commit('setOTPDeliveryMsg', otpDeliveryMsg1)
                } else if(resp.responseCode === "20") {
                  if(state.currentStep === state.OTP_DELIVERED) {
                    commit('setCurrentStep', state.FLOW_COMPLETED)
                    // logger.info('Transaction completed')
                    dispatch('checkVerificationStatus')
                  }
                } else if(resp.responseCode === "00") {
                    if(state.currentStep == 'VZIGINSTANCE_CREATED') {
                      //console.log("state.currentStep hereee...",state.currentStep)
                      commit('setCurrentStep', state.START_VERIFICATION)
                      //console.log("payload here..",payload)
                      state.vzigInstance.startVerification(response.data.data.listOfDevices[0].deviceID)
                      commit('setPollingLoader', true)
                      state.selectedOtpDevice.deviceID = response.data.data.listOfDevices[0].deviceID
                      commit('setHideOtpValidateComponent', false)
                      //console.log("state.hideOtpValidateComponent...",state.hideOtpValidateComponent)
                    }
                }
              commit('setPollingLoader', false)
              }
              if(response.data.responseCode == '24') {
                // no otp devices for the user
                // logger.info('No registered OTP devices')
                if(state.flowType === 'forgot_password') {
                  commit('setCannotProceedRecovery', true)
                  // logger.warn('The user cannot proceed with password recovery')
                  var msg = [{msg: state.messages.en.message["forgot_pd_cannot_proceed"], type: 'list'}]
                  // dispatch('forgotflow/updateAlerts', msg, {root:true})
                  errorSet(state.messages.en.message["forgot_pd_cannot_proceed"]);
                  commit('setFpAlert', msg)
                } else if (state.flowType === 'forgot_username') {
                  commit('setCannotRecoverUsername', true)
                  var msg = [{msg: state.messages.en.message["forgot_username_cannot_proceed"], type: 'error'}]
                  // dispatch('forgotflow/updateAlerts', msg, {root:true})
                  errorSet(state.messages.en.message["forgot_username_cannot_proceed"]);
                  commit('setFuAlert', msg)
                } else {
                  var msg = {msg: state.messages.en.message["login_otp_no_devices"], type:'error'}
                  errorSet(state.messages.en.message["login_otp_no_devices"]);
                  var otpAlertArr = state.otpAlerts
                  otpAlertArr.push(msg)
                  commit('setOTPAlert', otpAlertArr)
                  dispatch('formatDevices', [])
                }
              } else if(response.data.responseCode == '23') {
                if(state.flowType === 'forgot_password') {
                  commit('setCannotProceedRecovery', true)
                  // logger.warn('The user cannot proceed with password recovery')
                  var msg = [{msg: state.messages.en.message["forgot_pd_cannot_proceed"], type: 'list'}]
                  // dispatch('forgotflow/updateAlerts', msg, {root:true})
                  errorSet(state.messages.en.message["forgot_pd_cannot_proceed"]);
                  commit('setFpAlert', msg)
                
                }
              } else if(response.data.responseCode == '25') {
                if(state.flowType === 'forgot_password') {
                commit('setCannotProceedRecovery', true)
                  // logger.warn('The user cannot proceed with password recovery')
                  var msg = [{msg: state.messages.en.message["forgot_pd_cannot_proceed"], type: 'list'}]
                  // dispatch('forgotflow/updateAlerts', msg, {root:true})
                  errorSet(state.messages.en.message["forgot_pd_cannot_proceed"]);
                  commit('setFpAlert', msg) 
                } else if(state.flowType === 'forgot_username') {
                  commit('setCannotRecoverUsername', true)
                  var msg = [{msg: state.messages.en.message["forgot_username_cannot_proceed"], type: 'error'}]
                  // dispatch('forgotflow/updateAlerts', msg, {root:true})
                  errorSet(state.messages.en.message["forgot_username_cannot_proceed"]);
                  commit('setFuAlert', msg)
                } else {
                  // auth flow
                  var msg = {msg: state.messages.en.message["login_no_devices_no_questions"], type:'error'}
                  errorSet(state.messages.en.message["login_no_devices_no_questions"]);
                  var otpAlertArr = state.otpAlerts
                  otpAlertArr.push(msg)
                  commit('setOTPAlert', otpAlertArr)
                }
              }
              commit('createVZIGInstance', {
                  transactionID,
                  connType,
                  igBackendUrl,
                  callBackFunction
              })
              commit('setCurrentStep',state.VZIGINSTANCE_CREATED)
              if(state.flowType=== 'forgot_username') {

                // skip this message if otp device was previously blocked
                if(!state.otpDeviceBlockEvent && !state.selectedOtpDevice.deviceType) {
                  var msg = [ {	msg : state.messages.en.message['forgot_username_user_details_verified'],type : 'success'} ]
                  commit('setOTPAlert', msg)
                }
                
                eventTracking('Username Recovery Success',response.data)
              }
              commit('setUserValidationError', false)
              commit('setUserValidationContactAdminError', false)
              dispatch('updateStepupReason', response.data.stepupReason)
              commit('setFlowInitialized', response.data.status)
              commit('setLoader', false)
            }).catch((error) => {
              // if(hasAccess == false && responseCode == '0') {
              //  // retry message
              // } else if(hasAccess == false && responseCode == '1') {
              //   // maxattepmts message
              // } else {
              //   // success message
              // }
              commit('setOTPAlert', [])
              if(error.response.data.responseMessage == 'WARNING_SELECT_ANOTHER_DEVICE') {
                msg = [{msg: state.messages.en.message["reg_wireless_verify_number_error"], type:'warning'}]
                errorSet(state.messages.en.message['reg_wireless_verify_number_error']);
              } else if(error.response.data.responseMessage == 'UID_LOCKED') {
                msg = [{msg: state.messages.en.message["reg_wireless_verify_maxattempts_error"], type:'warning'}]
                errorSet(state.messages.en.message['reg_wireless_verify_maxattempts_error']);
              } else if(error.response.data.responseMessage == 'WARNING_ACCOUNT_LOCK_PREVENTION') {
                msg = [{msg: state.messages.en.message["reg_wireless_verify_final_attempt_error"], type:'warning'}]
                errorSet(state.messages.en.message['reg_wireless_verify_final_attempt_error']);
              } else {
                // for all other scenarios please modify the message
                msg = [{msg: state.messages.en.message["reg_wireless_verify_number_error"], type:'warning'}]
                errorSet(state.messages.en.message['reg_wireless_verify_number_error']);
              }
              commit('setWirelessAlerts', msg);
              commit('setHideOtpValidateComponent', true);
            });
          } else {
            commit('setHideOtpValidateComponent', true);
          }
        } else {
          commit('setCurrentStep', state.START_VERIFICATION)
          if(state.flowType == 'forgot_username'){
            dispatch('pollingEvent', {requestType: '2FA_INITIALIZE', deviceID: payload})
          } else {
          state.vzigInstance.startVerification(payload)
          }
          commit('setPollingLoader', true)
          if(!state.vbgFlow) {
            if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
              // rootState will give all the state accross the all modules in the store
              // Showing Tokenizedflow for polaris user
              let sdar_user = rootState?.profile.sdarUserType;
              if (iamvar_pwdLessFlowType != '' || (iamvar_polarisFlowToggle && sdar_user?.includes('PEND_SDAR')) || pwd_less_new_login) {
                commit('setHideOtpValidateComponent', false)
              } else {  
                commit('setHideOtpValidateComponent', true)
              }
            } else if((state.selectedOtpDevice.deviceType == 'BASIC') || (state.selectedOtpDevice.deviceType == 'IVR')) {
              commit('setHideOtpValidateComponent', false)
            }
          } else {
            commit('setHideOtpValidateComponent', false)
          }
        }
      },
      cancelVerification({state, commit, dispatch}) {
        if(state.flowType == 'forgot_username'){
          commit('setCancelPolling', true)
          dispatch('pollingEvent', {requestType: '2FA_CANCEL'})
        } else {
        state.vzigInstance.cancelVerification()
        }
        commit('setPollingLoader', true)
        if(state.flowType === 'forgot_password') {
          commit('setCurrentStep', state.verification_canceled)
        }
        commit('setHideOtpValidateComponent', true)
      },
      /*cancelVerification({state, commit}) {
        var payload = {
          'requestType': '2FA_CANCEL',
          'transactionID': state.transactionId
        }
        return playrest.post(iamvar_iamStepupServ+'/2fa/initialize', payload).then(function (response) {
          commit('setHideOtpValidateComponent', true)
        }).catch((error) => {
          //console.log('entered catch()')
          logger.error('Error in cancel '+error)
          throw error
        })
      },*/
      async resendOtpToDevice({dispatch}, payload) {
        await dispatch('cancelVerification')
        await dispatch('initializeStepup')
        await dispatch('getOtpDevices')
        dispatch('setOldSelectedDevice', payload)
        dispatch('setSelectedOtpDevice')    
      },
      validateOtp({commit, state, dispatch}, payload) {
        commit('setUserDeviceRegistration', payload.saveFingerprint)
        commit('setCurrentStep', state.OTP_DELIVERED)
        if(state.flowType === 'forgot_password') {
          commit('setLoader', true)
        }
        var newPayload = {
          securePin: payload.otpCode
        }
        if((payload.otpCode != undefined) || (payload.otpCode != 'undefined')) {
          state.secureOtpPin = payload.otpCode
        }
        if(state.flowType == 'forgot_username'){
          dispatch('pollingEvent', {requestType :'2FA_PIN_VERIFY', pin: payload.otpCode})
        } else {
        state.vzigInstance.verifyPin(newPayload)
        }
        commit('setPollingLoader', true)
      },
      checkVerificationStatus({commit, state, dispatch}) {
        commit('setLoader', true)
        let payload = ''
        if(state.flowType === 'auth_sqa_flow') {
          payload = {
            'saveFingerprint': state.registerDevice,
            'vbgFlow': state.vbgFlow
          }
        } else {
          payload = {
            'saveFingerprint': state.registerDevice,
            'vbgFlow': state.vbgFlow,
            'pin': state.secureOtpPin
          }
        }  
        let reqTransactionId = state.transactionId

        // if(!state.vbgFlow){
        //   reqTransactionId = state.vbgTranscationId
        //   //reqTransactionId = state.forgotPwdSqaTransactionId
        // }
        //console.log("reqTransactionId ",reqTransactionId)
        return playrest.post(iamvar_iamStepupServ+'/confirm-success/'+reqTransactionId, payload).then(function (response) {
          commit('setLoader', false)
          //console.log(state.currentStep)
          commit("setVbgTranscationId",reqTransactionId);
          if(!state.vbgFlow){
            if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
              if(iamvar_pwdLessFlowType != '') {
                //commit('setHideOtpValidateComponent', false)
              } else {
                commit('setHideOtpValidateComponent', true)
              }
            } 
          }
          if (response.data.responseCode === '00') {
            // Navigating verify delivery component when skipoptioneligible header is on response headers
            if(response.headers?.['skipoptioneligible']){
              // Show the skip option if value has true otherwise hide it
              response.headers?.['skipoptioneligible'] == 'true' ? commit('setSkipOption', true) :commit('setSkipOption', false)
              return router.push({path:'/login/verify/delivery'});
            }
            if(state.verificationMode === 'otp') {
              commit('setCurrentStep', state.OTP_VALIDATED)
              eventTracking('One Time Passcode Success','')
              commit('setFingerprintSaved', true)
              commit('setOtpValidationError', false)
              if(state.flowType === 'forgot_password') {
                //Account validated message
                // let successMsg = [{msg: state.messages.en.message["forgot_pd_successfully_verified"], type: 'success'}]
                // // dispatch('forgotflow/updateAlerts', msg, {root:true})
                // errorSet(state.messages.en.message["forgot_pd_successfully_verified"]);
                //  commit('setFpAlert', successMsg)
                // dispatch('updateFpAlerts', successMsg, {root:true})
               // commit('setOTPAlert', [ { msg: state.messages.en.message["forgot_pd_successfully_verified"], type: 'information' } ])
               // commit('setOTPDeliveryMsg', state.messages.en.message["forgot_pd_successfully_verified"])
              }
            } else if(state.verificationMode === 'sqa') {
              commit('setCurrentStep', state.SQA_VALIDATED)
              commit('setFingerprintSaved', true)
              commit('setSqaValidationError', false)
              commit('setForgotPwdSqaTransactionId', response.data.transactionID)
            }

            let iamvar_profileFlowCookie = null;
            if(document.cookie.indexOf('iamvar_profileFlow') !== -1) {
              iamvar_profileFlowCookie = getCookie('iamvar_profileFlow')
            }
            if (iamvar_profileFlowCookie == "true") {
              document.cookie = 'iamvar_transactionID = '+ state.transactionId + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure+';comment=setting in ui';
              document.cookie = 'iamvar_selectedOtpDeviceID = '+ state.selectedOtpDevice.deviceID + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure+';comment=setting in ui';
            }
          } else if(response.data.responseCode === '01') {
            var msg;
            if(state.verificationMode === 'otp') {
              commit('setCurrentStep', state.OTP_INVALIDATED)
              commit('setFingerprintSaved', false)
              commit('setOtpValidationError', true)
              if(!state.vbgFlow){
                if(state.flowType == 'auth_sqa_flow') {
                  msg = [{msg: state.messages.en.message["cqa_answer_invalid"], type:'error'}]
                  errorSet(state.messages.en.message['cqa_answer_invalid']);
                  commit('setOTPAlert', msg)
                }else if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
                  dispatch('updateSelectedDevice', state.defaultSelectedDevice);
                  commit('setUserDeniedOTP', true);
                  commit('setTokenizedFlow', true);
                  commit('setEmailDeniedNotification', true);
                  msg = [{msg: state.messages.en.message["tok_otp_deny_code_error"], type:'error'}]
                  errorSet(state.messages.en.message['tok_otp_deny_code_error']);
                  commit('setFpAlert', msg)
                } else if((state.selectedOtpDevice.deviceType == 'BASIC') || (state.selectedOtpDevice.deviceType == 'IVR')) {
                  commit('setUserDeniedOTP', false);
                  commit('setBasicDeniedNotification', true);
                  if(max_attempt_toggle != true){
                    commit('setHideOtpValidateComponent', true)
                  }
                  msg = [{msg: state.messages.en.message["reg_otp_verify_code_error"], type:'error'}]
                  errorSet(state.messages.en.message['reg_otp_verify_code_error']);
                  commit('setFpAlert', msg)
                }
              }else{
                commit('setUserDeniedOTP', false);
                msg = [{msg: state.messages.en.message["reg_otp_verify_code_error"], type:'error'}]
                errorSet(state.messages.en.message['reg_otp_verify_code_error']);
                commit('setFpAlert', msg)
              }
              if (iamvar_pwdLessFlowType != '' || pwd_less_new_login) {
                if (!state.vbgFlow && (max_attempt_toggle != true || state.tokenizedFlow )) { 
                  commit('setTokenizedFlow', false);                 
                  if(state.flowType.includes('forgot')) router.push({ path: '/login/stepup/otpdelivery' })
                  else router.push({ path: '/login/otpdelivery' })
                } else {
                  msg = [{msg: state.messages.en.message["reg_otp_verify_code_error"], type:'error'}]
                  errorSet(state.messages.en.message['reg_otp_verify_code_error'])
                  commit('setOTPAlert', msg)
                  commit('setPwdInlineError', state.messages.en.message["pwdless_otp_inline_error"])
                  if(document.getElementById('otpCode')) {
                    document.getElementById('otpCode').classList.add('pwdLessAlert')
                  }
                }
              } else {
                commit('setOTPAlert', msg)
              }
            } else if(state.verificationMode === 'sqa') {
              commit('setCurrentStep', state.SQA_INVALIDATED)
              commit('setFingerprintSaved', false)
              commit('setSqaValidationError', true)
              msg = [{msg: state.messages.en.message["incorrect_answer_error"], type:'error'}]
              errorSet(state.messages.en.message['incorrect_answer_error']);
              commit('setSQAAlert', msg)
            }
          } else if(response.data.responseCode === '22') {
            if(state.verificationMode === 'otp') {
              commit('setCurrentStep', state.OTP_INVALIDATED)
              commit('setFingerprintSaved', false)
              commit('setOtpValidationError', true)
              if(state.vbgFlow) {
                msg = [{msg: state.messages.en.message["reg_otp_verify_code_maxattempts_error"], type:'error'}]
                errorSet(state.messages.en.message['reg_otp_verify_code_maxattempts_error']);
              } else {
                msg = [{msg: state.messages.en.message["reg_otp_verify_code_maxinvalidattempts_error"], type:'error'}]
                errorSet(state.messages.en.message['reg_otp_verify_code_maxinvalidattempts_error']);
              }
              if(state.flowType == 'forgot_password') {
                dispatch('updateUserQuestions', []);
                commit('setSelectedQuestion')
                commit('enableFFSQAoption', false)
              }
              setTimeout(function () {
                // set state to prevent unwanted messages after page reload
                commit('setOtpDeviceBlockEvent', true)

                dispatch('initializeStepup')
                dispatch('getOtpDevices')
              }, 6000)
              if(max_attempt_toggle == true) {
                if((state.flowType == 'auth' || (state.flowType == 'login_assistant' && iamvar_loginAssistant))  && state.urlOnMount.includes('/account/business/login/unifiedlogin')) {
                  router.push({ path: '/login/otpdelivery' })
                } else if((state.flowType == 'auth' || (state.flowType == 'login_assistant' && iamvar_loginAssistant)) && !state.urlOnMount.includes('/account/business/login/unifiedlogin')) {
                  commit('setMaxAttemptError', true)
                } else if (state.flowType == 'forgot_password' && !state.otpDevices.length) {
                  router.push({ path: '/login/deliveryMethodLocked' })
                }
                commit('setShowChangeError', true)
              }
              commit('setOTPAlert', msg)
            } else {
              commit('setCurrentStep', state.SQA_INVALIDATED)
              commit('setFingerprintSaved', false)
              commit('setSqaValidationError', true)
              msg = [{msg: state.messages.en.message["incorrect_answer_maxattempts_error"], type:'list'}]
              errorSet(state.messages.en.message['incorrect_answer_maxattempts_error']);
              commit('setSQAAlert', msg)
              commit('setDisableSqaFields', true)
            }

          } else if(response.data.responseCode === '07') {
            if(state.verificationMode === 'otp') {
              if(!state.vbgFlow){
                if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
                  commit('setCurrentStep', state.OTP_INVALIDATED)
                  commit('setFingerprintSaved', false)
                  commit('setOtpValidationError', true)
                  commit('setUserDeniedOTP', true);
                  dispatch('updateSelectedDevice', state.defaultSelectedDevice);
                  msg = [{msg: state.messages.en.message["tok_otp_deny_code_error"], type:'error'}]
                  errorSet(state.messages.en.message['tok_otp_deny_code_error']);
                } else if((state.selectedOtpDevice.deviceType == 'BASIC') || (state.selectedOtpDevice.deviceType == 'IVR')) {
                  //expired otp
                  commit('setCurrentStep', state.OTP_EXPIRED)
                  msg = [{msg: state.messages.en.message["otp_expiry"], type:'error'}]
                  errorSet(state.messages.en.message['otp_expiry']);
                  commit('setOTPAlert', msg)
                  commit('setUserDeniedOTP', false);
                }
              }else{
                //expired otp
                commit('setCurrentStep', state.OTP_EXPIRED)
                msg = [{msg: state.messages.en.message["otp_expiry"], type:'error'}]
                errorSet(state.messages.en.message['otp_expiry']);
                commit('setOTPAlert', msg)
                commit('setUserDeniedOTP', false);             
                }
            }
            
          } else if(response.data.responseCode === '09') {
            //console.log('=')
          } else if(response.data.responseCode === '39') {
            router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' }}) 
          } else if(response.data.responseCode === '31') {
            iamvar_pwdlessVetLock = true
            router.push({ name: 'PwdLessOption'}) 
          }
          commit('setLoader', false)
        }).catch(function (error) {
          // commit('setFingerprintSaved', false)

          // generic network error
          commit('setOTPAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
          commit('setSQAAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
          // log unhandled error
          let requestObject = { reqUrl: restEndpoint, reqBody: payload, reqHeaders: state.options }
          let explanation = "This call checks if verification (OTP) was successful"

          let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

          dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
          
          commit('setLoader', false)
          throw error
        })
      },
      unableToReceiveOtp ({commit, state}) {
        commit('setLoader', true)
        var msg = state.messages.en.message["login_otp_unable_receive_modal_body"]
        errorSet(state.messages.en.message['login_otp_unable_receive_modal_body']);
        // v1/otp/logUnableToReceiveOtpEvent
        // http://localhost:9002/logUnableToReceiveOtpEvent
        commit('setOtpDialogTitle', 'Unable to receive One Time Passcode?')
        return playrest.get('/logUnableToReceiveOtpEvent').then(function (success) {
          commit('setLoader', false)
          var replaceString = msg.replace('$0', success.data)
          commit('setOtpDialogMsg', replaceString)
        }).catch (function (error) {
          commit('setLoader', false)
          var replaceString = msg.replace('$0', 'Customer Support')
          commit('setOtpDialogMsg', replaceString)
          throw error
        })
      },
      updateFuAlerts({commit}, payload) {
        commit('setFuAlert', payload)
      },
      updateFpAlerts({commit}, payload) {
        commit('setFpAlert', payload)
      },
      updateSetUserDeviceRegistration({commit}, payload) {
        commit('setUserDeviceRegistration', payload)
      },
      validateSqa ({commit, state, dispatch}, payload) {
        //console.log('from cvsstepup module: ', payload.sessionCookie)
        //console.log('from cvsstepup module: ', payload.challengeMethod)
        commit('setLoader', true)
        commit('setUserDeviceRegistration', payload.saveFingerprint)
        var newPayload = {
          'requestType': 'VALIDATE_SQA',
          'sqaValue': payload.sqa.answer,
          'transactionID': state.transactionId,
          "vbgFlow": state.vbgFlow
        }

        commit('sanitizePayloadForLogging', newPayload)
        
        // '/v1/sqa/verify', payload
        let reqTransactionId = state.transactionId
        return playrest.post(iamvar_recoveryServ+'/validate-sqa', newPayload).then(function (response) {
          commit('setLoader', false)
          commit("setVbgTranscationId",reqTransactionId);
          if(!state.vbgFlow){
            if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
              commit('setHideOtpValidateComponent', true)
            } 
          }
          if (response.data.responseCode === '00') {
            if(state.flowType == 'forgot_password') {
              commit('setCurrentStep', state.SQA_VALIDATED)
              commit('setFingerprintSaved', true)
              commit('setSqaValidationError', false)
              commit('setForgotPwdSqaTransactionId', response.data.transactionID)
              commit('setSQAValidated', true)
              dispatch('updateTriggerSendOTP', true)
              if(pwd_less_new_login){
                dispatch('sendOtpToDevice', state.selectedOtpDevice.deviceID)
                if(state.gotoUrl) router.push({path: '/login/stepup/otpvalidate', query: { goto: state.gotoUrl }})
                else router.push({path: '/login/stepup/otpvalidate'})
              }
            } else {
              dispatch('checkVerificationStatus')
            }
          } else if(response.data.responseCode === '01') {
            commit('setCurrentStep', state.SQA_INVALIDATED)
            commit('setFingerprintSaved', false)
            commit('setSqaValidationError', true)
            var msg = [{msg: state.messages.en.message["incorrect_answer_error"], type:'error'}]
            // errorSet(state.messages.en.message['incorrect_answer_error']);
            commit('setSQAAlert', msg)
            commit('setOTPAlert', msg)
          } else if(response.data.responseCode === '22') {
            commit('setCurrentStep', state.SQA_INVALIDATED)
            commit('setFingerprintSaved', false)
            commit('setSqaValidationError', true)
            commit('setDisableSqaFields', true)
            var msg = [{msg: state.messages.en.message["incorrect_answer_maxattempts_error"], type:'list'}]
            // errorSet(state.messages.en.message['incorrect_answer_maxattempts_error']);
            commit('setFpAlert', msg) 
            commit('setSQAAlert', msg)
            commit('setOTPAlert', msg)
            if(state.flowType == "forgot_password") {
              router.push({ name: 'AMForgotPwdComponent'})
            }
          }
        }).catch(function (error) {
          commit('setFingerprintSaved', false)

          // generic network error
          commit('setSQAAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
          commit('setOTPAlert', [ { msg: state.messages.en.message['bckend_unknown_err'], type: 'error' } ])
          commit('setLoader', false)
          throw error
        })
      },
      updateOtpSent ({commit}, payload) {
        commit('setOtpSent', payload)
      },
      updateResendPasscodeToSelectedDevice({commit}, payload) {
        commit('setResendPasscode', payload)
      },
      updateResendVerificationToSelectedDevice({commit}, payload) {
        commit('setResendVerification', payload)
      },
      updatecantrcvLink({commit}, payload) {
        commit('setcantrcvLink', payload)
      },
      updateShowChangeError({commit}, payload) {
        commit('setShowChangeError', payload)
      },
      updateVerificationSent({commit}, payload) {
        commit('setVerificationSent', payload)
      },
      updateShowDialog ({commit}, payload) {
        commit('setShowDialog', payload)
      },
      updateValidOtpForm ({commit}, payload) {
        commit('setValidOtpForm', payload)
      },
      updateUnableToReceiveOtpDialog({commit}, payload) {
        commit('setUnableToReceiveOtpDialog', payload)
      },
      updateVBGCCookieValue({commit}, payload) {	
        commit('setVBGCCookieValue', payload)	
      },	
      updateGotoUrl({commit}, payload) {	
        commit('setGoto', payload)	
      },
      getSessionCookie({ commit }) { 
        // get the cookie and continueUrl, store them in state
        let availableCookies = document.cookie
        // split available cookies between ';'
        let cookieArray = availableCookies.split(';')
        
        let amCookie = ''
        // get cookie from array
        for(let i = 0; i < cookieArray.length; i++) {
          let currentVal = cookieArray[i]
          // remove all spaces
          currentVal = currentVal.replace(/\s/g, '')

          // get cookie name (before '=')
          currentVal = currentVal.split('=')
          let currCookieName = currentVal[0]

          // delete vbgc cookie
          if(currCookieName === iamvar_vbgcCookieName) {
            for(let j = 0; j < iamvar_vbgcCookieDomain.length; j++) {
              document.cookie = iamvar_vbgcCookieName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + iamvar_vbgcCookieDomain[j] + ";path=/;secure=true"
            }
          }

          if(currCookieName === iamvar_amSessionCookieName) {
            // cookie found, assign to variable
            amCookie = currentVal[1]
            break
          }
        }

        // assign cookie to state
        commit('setSessionCookie', amCookie)
      },
      displayPageTimeoutWarning({commit, dispatch}, payload) {
        commit('displayPageTimeout', payload)
        if(payload===false) {
          dispatch('startPageTimer')
        }
      },
      startPageTimer({state, dispatch}) {
        var time = (iamvar_vbgcCookieExpiryInMins - 5) * 60 * 1000
        // var time = 1 * 60 * 1000
        setTimeout(function () {
            dispatch('displayPageTimeoutWarning', true)
            var warningtimeout = 15 * 60 * 1000
            // var warningtimeout = 1 * 60 * 1000
            setTimeout(function () {
              // session timed out 
              window.location.href = state.urlOnMount
            }, warningtimeout)
        }, time)
      },
      updateOTPAlwaysOn({state, commit}, payload) {
        commit('setOTPAlwaysOn', payload)
      },
      updateRecaptchaResponse({ commit }, payload) {
        commit('setRecaptchaResponse', payload)
      },
      sendUnhandledErrorToBackend({ commit }, payload) {
        let unhandledErrorObject = { };
        let debugApiClient = axios.create({
            baseURL: iamvar_serverUrl
        });

        unhandledErrorObject.request = payload.request;
        unhandledErrorObject.response = payload.response;
        unhandledErrorObject.expected = payload.explanation;

        //debugApiClient.post('/account/debug/info?output=json', unhandledErrorObject).then(function(res) {
        //    console.log('Error logging successful ', res);
        //}).catch(function(err) {
        //    console.log('Error logging error ', err);
        //});
      },
      sanitizePayloadForLogging({ commit }, payload) {
        // Make a copy of the payload
        let sanitizedPayload = JSON.parse(JSON.stringify(payload));

        // Sanitize SQA
        if(sanitizedPayload.requestType == "2FA_CQA_VERIFY") {
          sanitizedPayload.sqaValue = "*****";
        }

        commit('setSanitizedPayload', sanitizedPayload)
      },
      updateUserIdFlow({ commit }, payload) {
        commit('setUserIdFlow', payload)
      },
      updateVzottCookie({ commit }, payload) {
        commit('setVzottCookie', payload)
      },
      updateUserDeniedOTP({ commit }, payload) {
        commit('setUserDeniedOTP', payload)
      },
      updateCvsAuthAlert({ commit }, payload) {
        commit('setCvsAuthAlert', payload)
      },
      updateOTPAlert({ commit }, payload) {
        commit('setOTPAlert', payload)
      },
      updateDnrStatus({ commit }, payload) {
        commit('setDnrStatus', payload)
      },
      updateSetDeviceInfo({ commit }, payload) {
        commit('setDeviceInfo', payload)
      },
      updateRemoveMessage({ dispatch }, payload) {
        if(payload) {
          setTimeout(function () {
            dispatch('updateCvsAuthAlert',null)
            // dispatch('login/setAuthErrorUpdate',false, {root:true})
            // dispatch('login/setAlertTypeUpdate', 'error', {root:true})
          }, 10000)
      }
      },
      updateSetPreviousOtpTransactionID({ commit }, payload) {
        commit('setPreviousOtpTransactionID', payload)
      },
      updateClearInputFields({commit}, payload) {
        commit('setClearInputFields', payload)
      },
      updateHideOtpValidateComponent({ commit }, payload) {
        commit('setHideOtpValidateComponent', payload)
      },
      updateFFSQAEnabled({ commit }, payload ) {
        commit('enableFFSQAoption', payload )
      },
      updateTriggerSendOTP({commit}, payload ) {
        commit('setTriggerSendOTP', payload)
      },
      updateSQAValidated({commit}, payload ) {
        commit('setSQAValidated', payload)
      },
      updateVerificationMode({commit}, payload ) {
        commit('setVerificationMode', payload)
      },
      updateUserQuestions({commit}, payload) {
        commit('setUserQuestions', payload)
      },
      updateSelectedQuestion({commit}) {
        commit('setSelectedQuestion')

      },
      getVcgCsqaQuestion({state, commit}) {
        console.log("state.vzigInstance.getSQAOption()..",state.vzigInstance)
        commit('setVerificationMode', 'otp')
        state.vzigInstance.getSQAOption()
      },
      updateFormatVcgSecretQuestion({commit},payload) {
        commit('setFormatVcgSecretQuestion', payload)
      },
      sendVcgVerifyPin({commit, state}, payload) {
        commit('setLoader', true)
        commit('setCurrentStep', state.OTP_DELIVERED)
        state.vzigInstance.verifySQA(payload)
      },
      pollingEvent({commit, state, dispatch}, payload) {
        var newPayload = null
        if(payload.requestType === '2FA_DEVICE_OPTIONS') {
          newPayload = {
            'deviceFp' : getDeviceFingerprintInfoWithoutGeolocation().value,
            'requestType' : '2FA_DEVICE_OPTIONS',
            'transactionID' : state.transactionId
          }
        } else if(payload.requestType === '2FA_INITIALIZE') {
          newPayload = {
            'deviceID' : payload.deviceID,
            'requestType' : '2FA_INITIALIZE',
            'transactionID' : state.transactionId
          }
        } else if(payload.requestType === '2FA_PIN_VERIFY') {
          newPayload = {
            'pin' : payload.pin,
            'requestType' : '2FA_PIN_VERIFY',
            'transactionID' : state.transactionId
          }
        } else if(payload.requestType === '2FA_CANCEL') {
          commit('setCancelPolling', true)
          newPayload = {
            'requestType' : '2FA_CANCEL',
            'transactionID' : state.transactionId
          }
        } else if(payload.requestType === '2FA_UPDATE') {
          commit('setCancelPolling', false)
          newPayload = {
            'requestType' : '2FA_UPDATE',
            'transactionID' : state.transactionId
          }
        }
        let restClient = state.vbgFlow ? cvsplayrest : playrest
        let restEndpoint = ''
        let option = state.options
        if(!state.vbgFlow){ 
          restEndpoint = iamvar_iamStepupServ + '/request-stepup/polling'
        } else {
          restEndpoint = iamvar_cvsStepupServ+'/2fa/polling'
        }
        return restClient.post(restEndpoint, newPayload, option).then(function (response) {
            let resp = response.data
            console.log("polling response", response)
            if(!state.vbgFlow){
              if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
                if (iamvar_pwdLessFlowType != '') {
                  //commit('setHideOtpValidateComponent', false)
                } else {
                  commit('setHideOtpValidateComponent', true)
                }
              } 
            }
            if(resp.responseCode === "01" && !state.vbgFlow && max_attempt_toggle == true) {
                dispatch('checkVerificationStatus')
            } else if(resp.responseCode === "02") {
              //console.log('Processing Error')
              if(!state.vbgFlow){
                commit('setCurrentStep', state.IFG_TOKEN_PROCESS_ERROR)
                msg = [{msg: state.messages.en.message["tok_otp_code_process_error"], type:'error'}]
                errorSet(state.messages.en.message['tok_otp_code_process_error']);
                commit('setOTPAlert', msg)
              }
              // logger.error('Error in processing request')
            } else if(resp.responseCode === "03") {
              if(!state.vbgFlow){
                msg = [{msg: state.messages.en.message["tok_unable_to_send_sms"], type:'error'}]
                errorSet(state.messages.en.message['tok_otp_expired_code_error']);
                commit('setOTPAlert', msg)
             } else{
              commit('setCurrentStep', state.OTP_FAILED_TO_DELIVER)
              commit('setOtpSent', false)
              var msg = [{msg: state.messages.en.message["login_otp_send_error"], type:'danger'}];
              errorSet(state.messages.en.message["login_otp_send_error"]);
              commit('setOTPAlert', msg)
              commit('setHideOtpValidateComponent', true)
             }                
            } else if(resp.responseCode === "06") {
              // max attempts
              if(!state.vbgFlow && resp.responseMessage == "Max attempts reached"){
                msg = [{msg: state.messages.en.message["max_attempt_reached"], type:'error'}]
                errorSet(state.messages.en.message['max_attempt_reached']);
                // Redirecting to this screen , when Delivery option method exceeds & empty device list
                if(!state.otpDevices.filter(obj => obj.deviceType !== "select").length) {
                  state.flowType == 'auth_sqa_flow'? router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } }) : router.push({ path: '/login/deliveryMethodLocked' }) 
                }
                commit('setOTPAlert', msg)
                dispatch('formatDevices', [])
              }
            } else if(resp.responseCode === "07") {
              // token expired
              if(!state.vbgFlow){
                if((state.selectedOtpDevice.deviceType == 'EMAIL') || (state.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
                  commit('setCurrentStep', state.IFG_TOKEN_EXPIRED)
                  msg = [{msg: state.messages.en.message["tok_otp_expired_code_error"], type:'error'}]
                  errorSet(state.messages.en.message['tok_otp_expired_code_error']);
                  commit('setOTPAlert', msg)
                  // token expired reset dropdown state to default
                  dispatch('setResendSelectedDevice', state.selectedOtpDevice)
                  if(!pwd_less_new_login) dispatch('updateSelectedDevice', state.defaultSelectedDevice);
                } else if((state.selectedOtpDevice.deviceType == 'BASIC') || (state.selectedOtpDevice.deviceType == 'IVR')) {
                  commit('setCurrentStep', state.TOKEN_EXPIRED)
                  msg = [{msg: state.messages.en.message["otp_expiry"], type:'error'}]
                  errorSet(state.messages.en.message['otp_expiry']);
                  commit('setOTPAlert', msg)
                }
              }else{
                commit('setCurrentStep', state.TOKEN_EXPIRED)
                msg = [{msg: state.messages.en.message["otp_expiry"], type:'error'}]
                errorSet(state.messages.en.message['otp_expiry']);
                commit('setOTPAlert', msg)
              }
              // logger.warn('Transaction token expired')

            } else if(resp.responseCode === "09") {
              // invalid request
              if(!state.vbgFlow){
                if(!state.showChangeError) {
                  if(state.emailDeniedNotification) {
                    commit('setEmailDeniedNotification', false)
                    msg = [{msg: state.messages.en.message["tok_otp_deny_code_error"], type:'error'}]
                    errorSet(state.messages.en.message['tok_otp_deny_code_error']);
                    commit('setOTPAlert', msg)
                  } else if(state.basicDeniedNotification) {
                    commit('setBasicDeniedNotification', false)
                    msg = [{msg: state.messages.en.message["reg_otp_verify_code_error"], type:'error'}]
                    errorSet(state.messages.en.message['reg_otp_verify_code_error']);
                    commit('setOTPAlert', msg) 
                  } else {
                    commit('setShowChangeError', false)
                    commit('setCurrentStep', state.IFG_TOKEN_PROCESS_ERROR)
                    msg = [{msg: state.messages.en.message["tok_otp_code_process_error"], type:'error'}]
                    errorSet(state.messages.en.message['tok_otp_code_process_error']);
                    commit('setOTPAlert', msg)
                  } 
                }
                if(state.maxAttemptError){
                  commit('setMaxAttemptError', false)
                  if(state.vbgFlow) {
                    msg = [{msg: state.messages.en.message["reg_otp_verify_code_maxattempts_error"], type:'error'}]
                    errorSet(state.messages.en.message['reg_otp_verify_code_maxattempts_error']);
                  } else {
                    msg = [{msg: state.messages.en.message["reg_otp_verify_code_maxinvalidattempts_error"], type:'error'}]
                    errorSet(state.messages.en.message['reg_otp_verify_code_maxinvalidattempts_error']);
                  }
                  commit('setOTPAlert', msg)
                }
              }
            } else if(resp.responseCode === "10") {

            } else if(resp.responseCode === "13") {
              commit('setOtpSent', true)
              commit('setCurrentStep', state.OTP_DELIVERED)
              // logger.info('OTP delivered to the user selected device')
              // state.seelctedOtpDevice.label format = EMAIL   e***@e***.com, 3 spaces in between
              let deviceTypeLabel = state.selectedOtpDevice.label.split(' ')
              if(state.vbgFlow && !state.otpErrFlag) {
              // otpErrFlag = true;
              commit('setOTPErrFlag', true)
              if (iamvar_pwdLessFlowType == '') {
              let otpSentMsg = state.messages.en.message["login_otp_"+state.selectedOtpDevice.deviceType+"_code_sent"].replace("$0", deviceTypeLabel[deviceTypeLabel.length - 1])
              commit('setOTPAlert', [ { msg: otpSentMsg, type: 'success' } ])
              }
              commit('setOTPDeliveryMsg', otpDeliveryMsg1)
              }
              if(!state.vbgFlow && !state.otpErrFlag) {
                if((state.selectedOtpDevice.deviceType == 'BASIC') || (state.selectedOtpDevice.deviceType == 'IVR')) {
                  // otpErrFlag = true;
                  commit('setOTPErrFlag', true)
                  let otpSentMsg = state.messages.en.message["login_otp_"+state.selectedOtpDevice.deviceType+"_code_sent"].replace("$0", deviceTypeLabel[deviceTypeLabel.length - 1])
                  commit('setOTPAlert', [ { msg: otpSentMsg, type: 'success' } ])
                  commit('setOTPDeliveryMsg', otpDeliveryMsg1)
                } 
              }
              if(!state.cancelPolling && state.flowType == 'forgot_username' ) {
                setTimeout(()=> {
                  dispatch('pollingEvent', {requestType: '2FA_UPDATE'})
                },3000)
              }
            } else if(resp.responseCode === "11" || resp.responseCode === "12"  || (resp.responseCode === "14" && !vbgFlow)) {
              commit('setOtpSent', true)
              commit('setCurrentStep', state.OTP_DELIVERED)
              // logger.info('OTP delivered to the user selected device')
              // state.seelctedOtpDevice.label format = EMAIL   e***@e***.com, 3 spaces in between
              let deviceTypeLabel = state.selectedOtpDevice.label.split(' ')
              if(state.vbgFlow && !state.otpErrFlag) {
              // otpErrFlag = true;
              commit('setOTPErrFlag', true)
              if (iamvar_pwdLessFlowType == '') {
              let otpSentMsg = state.messages.en.message["login_otp_"+state.selectedOtpDevice.deviceType+"_code_sent"].replace("$0", deviceTypeLabel[deviceTypeLabel.length - 1])
              commit('setOTPAlert', [ { msg: otpSentMsg, type: 'success' } ])
              }
              commit('setOTPDeliveryMsg', otpDeliveryMsg1)
              }
              if(!state.vbgFlow && !state.otpErrFlag) {
                if((state.selectedOtpDevice.deviceType == 'BASIC') || (state.selectedOtpDevice.deviceType == 'IVR')) {
                  // otpErrFlag = true;
                  commit('setOTPErrFlag', true)
                  let otpSentMsg = state.messages.en.message["login_otp_"+state.selectedOtpDevice.deviceType+"_code_sent"].replace("$0", deviceTypeLabel[deviceTypeLabel.length - 1])
                  commit('setOTPAlert', [ { msg: otpSentMsg, type: 'success' } ])
                  commit('setOTPDeliveryMsg', otpDeliveryMsg1)
                } 
              }
            } else if(resp.responseCode === "20") {
              if(state.currentStep === state.OTP_DELIVERED) {
                commit('setCurrentStep', state.FLOW_COMPLETED)
                // logger.info('Transaction completed')
                dispatch('checkVerificationStatus')
              }
            } else if(resp.responseCode === "00") {
              commit('setCancelPolling', false)
              if(state.flowType === 'forgot_password' && !pwd_less_new_login) {
                if(state.currentStep === state.FLOW_INITIALIZED || state.currentStep === 'SQA_VALIDATED' || state.currentStep === "SQA_DISPLAYED") {
                  var devices = state.otpDevices.slice(1)
                  dispatch('formatDevices', devices)
                } else if(state.currentStep === state.START_VERIFICATION) {
                  commit('setOtpSent', true)
                  commit('setCurrentStep', state.OTP_DELIVERED)
                  var alertMessageString = state.selectedOtpDevice.label;
                  var device=alertMessageString.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
                  otpDeliveryMsg1 = state.messages.en.message["otp_validate_directions"].replace("$0", device[1]);
                }
              } else {
              if(state.currentStep === state.FLOW_INITIALIZED || state.currentStep === 'SQA_VALIDATED') {
                //console.log("resp.data...",resp.data)
                if(state.flowType == 'auth_sqa_flow') {
                  dispatch('updateFormatVcgSecretQuestion',resp.data.question)
                } else {
                  var devices = resp.data.options
                  if(state.vbgFlow){
                    // Formatting the delivery options order like text, email, call for login & forgot_username flows                     
                    // This feature is already existed in forgot password flows.
                      if(state.currentStep === state.FLOW_INITIALIZED){
                      // devices = response.data.data.listOfDevices;
                      dispatch('formatDevices', state.getTransactionDeliveryMethods);
                     }
                     else dispatch('formatDevices', state.getTransactionDeliveryMethods);
                  }
                  else if(!state.vbgFlow){
                    //console.log('@@@  response.data.data.listOfDevices ',  response.data.data.listOfDevices)
                    // var tokenizedDevices = response.data.data.listOfDevices;
                    dispatch('formatDevices', state.getTransactionDeliveryMethods);
                  }
                }
              } else if(state.currentStep === state.START_VERIFICATION) {
                commit('setOtpSent', true)
                commit('setCurrentStep', state.OTP_DELIVERED)
                if(!state.cancelPolling && state.flowType == 'forgot_username') {
                  dispatch('pollingEvent', {requestType: '2FA_UPDATE'})                  
                }
                // logger.info('OTP delivered to the user selected device')
                var alertMessageString = state.selectedOtpDevice.label;
                var device=alertMessageString.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
            // var otpDeliveryMsg1 = null;
            //     otpDeliveryMsg1 = state.messages.en.message["otp_validate_directions"].replace("$0", device[1]);
                // commit('setOTPDeliveryMsg', otpDeliveryMsg)
              }
            }
            } else if(resp.responseCode === "05") {
              msg = [{msg: state.messages.en.message["reg_otp_verify_code_profile_not_found"], type:'error'}]
              errorSet(state.messages.en.message['reg_otp_verify_code_profile_not_found']);
              commit('setOTPAlert', msg)
            } else if (response.data.transactionID === state.transactionId) {
              if(!state.cancelPolling && state.flowType == 'forgot_username'){
                setTimeout(()=> {
                  dispatch('pollingEvent', {requestType: '2FA_UPDATE'})
                },3000)
              }
            }
            // commit('setLoader', false)
            commit('setPollingLoader', false)
        }).catch((error) => {
          commit('setPollingLoader', false)
          console.log("come here")
        })

        
      },
    },
    getters: {
      getChangedSelectedOption: state => state.changedSelectedOption,
      submitLoader: (state) => state.submitLoader,
      showTlsWarn: (state) => state.showTlsWarn,
      devices: (state) => state.otpDevices,
      userQuestions: (state) => state.userQuestions,
      selectedQuestion: (state) => state.selectedQuestion,
      selectedOtpDevice: (state) => state.selectedOtpDevice,
      dynamicClasses: (state) => state.dynamicClasses,
      dynamicErrorClasses: (state) => state.dynamicErrorClasses,
      otpSent (state) {
        return state.otpSent
      },
      fingerprintSaved: (state) => state.fingerprintSaved,
      unableToReceiveOtpDialog (state) {
        return state.unableToReceiveOtpDialog
      },
      sqaDisabled: (state) => state.sqaDisabled,
      isPwReset: (state) => state.isPwReset,
      sqaAlerts: (state) => state.sqaAlerts,
      otpAlerts: (state) => state.otpAlerts,
      wirelessAlerts: (state) => state.wirelessAlerts,
      fuAlerts: (state) => state.fuAlerts,
      fpAlerts: (state) => state.fpAlerts,
      sqaSubmitLinkClass: (state) => state.sqaSubmitLinkClass,
      dialogMsg (state) {
        return state.otpDialogMsg
      },
      dialogTitle (state) {
        return state.otpDialogTitle
      },
      stateInitialized: (state) => state.stateInitialized,
      disableSqaFields: (state) => state.disableSqaFields,
      flowInitialized: (state) => state.flowInitialized,
      dialog (state) {
        return state.dialog
      },
      currentStep: (state) => state.currentStep,
      stepupReason: (state) => state.stepupReason,
      aaThrottled: (state) => state.aaThrottled,
      transactionId: (state) => state.transactionId,
      forgotPwdSqaTransactionId: (state) => state.forgotPwdSqaTransactionId,
      username: (state) => state.username,
      flowType: (state) => state.flowType,
      email: (state) => state.email,
      account: (state) => state.account,
      mtn: (state) => state.mtn,
      captchaText: (state) => state.captchaText,
      encryptedResp: (state) => state.encryptedResp,
      userValidationError: (state) => state.userValidationError,
      userValidationContactAdminError: (state) => state.userValidationContactAdminError,
      sqaEnabled: (state) => state.sqaEnabled,
      otpEnabled: (state) => state.otpEnabled,
      noSqa: (state) => state.noSqa,
      otpValidationError: (state) => state.otpValidationError,
      sqaValidationError: (state) => state.sqaValidationError,
      sqaPageMessages: (state) => state.sqaPageMessages,
      otpPageMessages: (state) => state.otpPageMessages,
      cannotProceedRecovery: (state) => state.cannotProceedRecovery,
      sessionCookie: (state) => state.sessionCookie,
      vzigInstance: (state) => state.vzigInstance,
      urlOnMount: (state) => state.urlOnMount,
      userAgent: (state) => state.userAgent,
      resendPasscodeToSelectedDevice: (state) => state.resendPasscodeToSelectedDevice,
      resendVerificationToSelectedDevice: (state) => state.resendVerificationToSelectedDevice,
      cantrcvLink: (state) => state.cantrcvLink,
      verificationSent: (state) => state.verificationSent,
      cannotRecoverUsername: (state) => state.cannotRecoverUsername,
      otpDeliveryMsg: (state) => state.otpDeliveryMsg,
      hideOtpValidateComponent: (state) => state.hideOtpValidateComponent,
      continueUrl: (state) => state.continueUrl,
      pageTimeout: (state) => state.pageTimeout,
      otpAlwaysOn: (state) => state.otpAlwaysOn,
      recaptchaResponse: (state) => state.recaptchaResponse,
      wirelessObject: (state) => state.wirelessObject,
      isMobilenumberValid: (state) => state.isMobilenumberValid,
      isShowWirelessNumber: (state) => state.isShowWirelessNumber,
      wirelessMob: (state) => state.wirelessMob,
      vbgFlow: (state) => state.vbgFlow,
      isSmbUser: (state) => state.isSmbUser,
      userIdFlow: (state) => state.userIdFlow,
      vzottCookie: (state) => state.vzottCookie,
      userDeniedOTP: (state) => state.userDeniedOTP,
      emailDeniedNotification: (state) => state.emailDeniedNotification,
      basicDeniedNotification: (state) => state.basicDeniedNotification,
      cvsAuthAlert: (state) => state.cvsAuthAlert,
      defaultSelectedDevice: (state) => state.defaultSelectedDevice,
      dnrStatus: (state) => state.dnrStatus,
      deviceInfo: (state) => state.deviceInfo,
      previousOtpTransactionID: (state) => state.previousOtpTransactionID,
      initialRouteToken : (state) => state.initialRouteToken,
      pwdInlineError: (state) => state.pwdInlineError,
      getregisterDevice: (state) => state.registerDevice,
      showChangeError: (state) => state.showChangeError,
      maxAttemptError: (state) => state.maxAttemptError,
      resendDevice: (state) => state.resendDevice,
      clearInputFields: (state) => state.clearInputFields,
      ffSQAEnabled: (state) => state.ffSQAEnabled,
      triggerSendOTP: (state) => state.triggerSendOTP,
      sqaValidated: (state) => state.sqaValidated,
      getFormatVcgSecretQuestion: (state) => state.formatVcgSecretQuestion,
      vpsUsersData: (state) => state.vpsUsersData,
      vpsSuccessPageEmail: (state) => state.vpsSuccessPageEmail,
      pollingLoader: (state) => state.pollingLoader,
      showMtnDeliveryMethod: (state) => state.showMtnDeliveryMethod,
      getSkipOption: state => state.skipOption,
    }  
}
